import React from 'react'
import { AppBar, IconButton, Toolbar } from '@mui/material'
import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrow-back.svg'
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg'
import Logo from 'components/Logo/Logo'
import { useNavigate, Link, useLocation } from 'react-router-dom'

const TopBar: React.FC = () => {
  const navigate = useNavigate()
  const { pathname, hash } = useLocation()
  const path = pathname.split('/')
  path.pop()
  const prevPath = path.join('/') || '/'
  let hashPath = ''
  if (prevPath !== '/') {
    hashPath = hash ?? ''
  }
  return (
    <AppBar
      position="sticky"
      sx={{
        boxShadow: 'none',
        background: (theme) =>
          `linear-gradient(180deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.light} 100%)`,
        mb: '44px',
        zIndex: (theme) => theme.zIndex.drawer + 2,
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {pathname !== '/' && (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => navigate(prevPath + hashPath)}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <IconButton
          sx={{ marginLeft: 'auto' }}
          size="large"
          edge="start"
          color="inherit"
          aria-label="profile"
          onClick={() => navigate('/profile')}
        >
          <ProfileIcon />
        </IconButton>
      </Toolbar>
      <Link to={'/'} style={{ position: 'absolute', top: '5px', left: '50%', transform: 'translateX(-50%)' }}>
        <Logo sx={{ width: '74px', height: '74px' }} />
      </Link>
    </AppBar>
  )
}

export default TopBar
