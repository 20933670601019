import React, { useId } from 'react'
import { Box, InputLabel, TextField, TextFieldProps, Typography } from '@mui/material'

interface TextInputProps {
  label?: string
  innerLabel?: string
  required?: boolean
  error?: string
  [prop: string]: any
}

const TextInput: React.FC<TextInputProps> = React.forwardRef((props, ref) => {
  const { label, innerLabel, required = false, error, ...rest } = props
  const id = useId()
  return (
    <Box>
      {label && !innerLabel && (
        <InputLabel
          required={required}
          htmlFor={id}
          sx={{
            fontSize: '14px',
            lineHeight: '21px',
            mb: '2px',
          }}
        >
          {label}
        </InputLabel>
      )}
      <TextField
        required={required}
        label={innerLabel}
        id={id}
        variant="outlined"
        fullWidth
        {...rest}
        error={!!error}
        helperText={error}
        inputProps={
          rest.type === 'number'
            ? {
                pattern: '[0-9]*',
              }
            : {}
        }
      />
    </Box>
  )
})

export default TextInput
