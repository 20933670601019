export const required = () => {
  return { required: 'Please provide a value' }
}

export const maxLength = (value: number, message?: string) => {
  return {
    value: value,
    message: message ?? `Length should be less then ${value} symbols`,
  }
}

export const minLength = (value: number, message?: string) => {
  return {
    value: value,
    message: message ?? `Length should be more then ${value} symbols`,
  }
}

export const max = (value: number, message?: string) => {
  return {
    value: value,
    message: message ?? `The value should be less then ${value}`,
  }
}

export const min = (value: number, message?: string) => {
  return {
    value: value,
    message: message ?? `The value should be more then ${value}`,
  }
}

export const phoneValidation = () => {
  return {
    pattern: {
      value: /^0[0-9]{9}$/,
      message: 'Vul de 10 cijfers van je telefoonnummer in, zonder spaties of landencode.',
    },
  }
}

export const postCodeValidation = () => {
  return {
    pattern: {
      value: /^[0-9]{4} [A-Z]{2}$/,
      message: 'Vul de 4 cijfers en 2 letters van je postcode in, bijvoorbeeld 1234 AB.',
    },
  }
}

export const houseNumberValidation = () => {
  return {
    pattern: {
      value: /^[1-9][0-9]{0,3}$/,
      message: 'Vul een geldig huisnummer in.',
    },
  }
}

export const kvkValidation = () => {
  return {
    pattern: {
      value: /^[0-9]{8}$/,
      message: 'Vul de 8 cijfers van je KVK nummer in, zonder spaties.',
    },
  }
}

export const vatValidation = () => {
  return {
    pattern: {
      value: /^NL[0-9]{9}B[0-9]{2}$/,
      message:
        'Jouw BTW nummer moet bestaan uit NL, 9 cijfers, de letter B, 2 cijfers (aan elkaar allemaal). Bijvoorbeeld NL000000000B00',
    },
  }
}

export const affixValidation = () => {
  return {
    pattern: {
      value: /^[\w\-\s]{0,5}$/,
      message: 'Vul een geldig toevoeging in.',
    },
  }
}
