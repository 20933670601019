import React, { useEffect, useState } from 'react'
import Layout from 'components/Layout/Layout'
import { useParams, useLocation } from 'react-router-dom'
import { Box, Stack, Typography, Button, Chip, TableRow, TableCell, CircularProgress } from '@mui/material'
import { useQuery, useMutation } from '@apollo/client'
import { GET_HOURS_DETAILS, CHANGE_HOURS_STATE } from 'graphql/queries'
import DataList from 'components/common/DataBox/DataBox'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close_alt.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'
import InputTable from 'components/common/InputTable/InputTable'
import Spoiler from 'components/common/Spoiler/Spoiler'
import TextInput from 'components/common/TextInput/TextInput'
import { trailZero } from 'utils/trailZero'
import { useForm, Controller } from 'react-hook-form'
import useNotification from 'hooks/useNotification'
import { Link } from 'react-router-dom'
import { authApi } from 'services/Auth'

const weekDays = ['Ma.', 'Di.', 'Wo.', 'Do.', 'Vr.', 'Za.', 'Zo.']

const HoursItemPage: React.FC<{ showBottomNavigation?: boolean }> = ({ showBottomNavigation }) => {
  const [formLoading, setFormLoading] = useState<boolean>(true)
  const [invoiceWasViewed, setInvoiceWasViewed] = useState<boolean>(false)
  const [invoiceConceptLoading, setInvoiceConceptLoading] = useState<boolean>(false)

  const { hoursId } = useParams()
  const { hash } = useLocation()
  const { data, loading, error } = useQuery(GET_HOURS_DETAILS, { variables: { id: hoursId } })
  const [changeHoursStatus, { data: statusChangeData, loading: statusChangeLoading, error: statusChangeError }] =
    useMutation(CHANGE_HOURS_STATE)
  const { getValues, control } = useForm()
  const notify = useNotification()

  const onStatusChange = (newStatus: string) => {
    const notes = Object.entries(getValues()).map(([key, value]) => {
      return {
        id: +key,
        note: value ?? '',
      }
    })

    const payload = {
      hour_id: hoursId,
      new_state: newStatus,
      project_hours_data: {
        project_hours_notes: notes,
      },
    }

    changeHoursStatus({
      variables: payload,
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GET_HOURS_DETAILS, variables: { id: hoursId } }],
    })
  }

  useEffect(() => {
    statusChangeError && notify('error', 'Something went wrong!')
    statusChangeData?.hour_state_change?.status && notify('success', statusChangeData?.hour_state_change?.statusMessage)
    if (statusChangeData?.hour_state_change?.errors.length) {
      notify('error', statusChangeData?.hour_state_change?.errors[0]?.message)
      setTimeout(() => {
        window.scrollTo({ top: document.getElementById('scroll-to')?.offsetTop, left: 0, behavior: 'smooth' })
      }, 800)
    }
  }, [statusChangeData, statusChangeError])

  useEffect(() => {
    setTimeout(() => {
      setFormLoading(false)
    }, 100)
  }, [])

  const blockedStatus = data?.hour?.statuses.reduce(
    (acc: any, status: any) => status.status === 'blocked' && status,
    false,
  )

  return (
    <Layout showBottomNavigation={showBottomNavigation} loading={loading || statusChangeLoading || formLoading}>
      <Typography variant="h1" display="inline">
        Urenoverzicht
      </Typography>
      {!(loading || formLoading) && (
        <>
          <div>
            {data?.hour.project_hours.map((item: any) => {
              return (
                <Spoiler
                  title={
                    item.related_project.profile.name +
                    ' ' +
                    item.related_project.title +
                    ' (' +
                    item.related_project.code +
                    ') '
                  }
                  key={item.id}
                  open={!!statusChangeData?.hour_state_change?.errors?.length && hash.includes(item.id)}
                >
                  <Stack spacing="20px">
                    {JSON.parse(item.data).map((week: any) => {
                      return (
                        <Box key={week.week_id}>
                          <Typography variant="h2" sx={{ marginBottom: '20px' }}>
                            Week {week.week_id}
                          </Typography>
                          <InputTable
                            head={['', 'Lesuren.', 'Niet Lesuren', 'KM’s']}
                            body={weekDays.map((row: any, i: any) => {
                              return (
                                <TableRow key={`${week.year}-${week.week_id}-${row}`}>
                                  <TableCell component="th" scope="row">
                                    {`${row} ${trailZero(week.week_days[i + 1].dayNumber)}/${trailZero(
                                      week.week_days[i + 1].monthNumber,
                                    )}`}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: '3px 6px 3px 6px!important',
                                      borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    }}
                                  >
                                    <TextInput
                                      type="number"
                                      value={week.week_days[i + 1].lessons}
                                      disabled
                                      sx={{
                                        '& .MuiOutlinedInput-root': {
                                          minWidth: '65px',
                                        },
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: '3px 6px 3px 6px!important',
                                      borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    }}
                                  >
                                    <TextInput
                                      type="number"
                                      value={week.week_days[i + 1].non_class}
                                      disabled
                                      sx={{
                                        '& .MuiOutlinedInput-root': {
                                          minWidth: '65px',
                                        },
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: '3px 6px 3px 6px!important',
                                      borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    }}
                                  >
                                    <TextInput
                                      type="number"
                                      value={week.week_days[i + 1].km}
                                      disabled
                                      sx={{
                                        '& .MuiOutlinedInput-root': {
                                          minWidth: '65px',
                                        },
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          />
                        </Box>
                      )
                    })}
                    <TextInput
                      value={item.project_manager_note ?? ''}
                      label="Toelichting opdrachtgever"
                      multiline={true}
                      rows={5}
                      disabled
                    />
                    {hash.includes(item.id) && <div id={'scroll-to'}></div>}
                    <Controller
                      name={`${item.id}`}
                      control={control}
                      defaultValue={item.professional_note ?? ''}
                      render={({ field }) => (
                        <TextInput
                          label="Gegeven toelichting goedkeuring/afkeuring"
                          error={
                            statusChangeData?.hour_state_change?.errors?.length && hash.includes(item.id)
                              ? statusChangeData?.hour_state_change?.errors[0]?.message
                              : undefined
                          }
                          multiline={true}
                          rows={5}
                          disabled={data.hour.statuses[0].status !== 'sent'}
                          {...field}
                        />
                      )}
                    />
                  </Stack>
                </Spoiler>
              )
            })}
          </div>
          <Typography variant="h2">Project totaal</Typography>
          <DataList
            data={[
              {
                key: <b>Omschrijving:</b>,
                value: <b>Ingevoerd</b>,
              },
              {
                key: 'Uren lesgebonden',
                value: `${data?.hour?.hours_total.total_lessons}`,
              },
              {
                key: 'Uren niet lesgebonden',
                value: `${data?.hour?.hours_total.total_non_class}`,
              },
              {
                key: 'Reiskilometers',
                value: `${data?.hour?.hours_total.total_km}`,
              },
            ]}
          />
          <Typography variant="h2">Status</Typography>

          {blockedStatus && (
            <DataList
              description={
                <Chip
                  label={
                    <>
                      <Typography variant="body1">{blockedStatus.status_text}</Typography>
                      {blockedStatus.blocking_projects && (
                        <ul>
                          {blockedStatus.blocking_projects.map((project: any) => (
                            <li key={project.id}>
                              <Link style={{ color: '#00476C' }} to={`/project/${project.id}`}>
                                {project.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </>
                  }
                  color={blockedStatus.status_class}
                  sx={{ mb: '10px' }}
                />
              }
              data={[]}
              button={
                <Stack spacing="10px">
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<CloseIcon />}
                    onClick={() => onStatusChange('rejected')}
                    disabled
                  >
                    Afkeuren
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<CheckIcon />}
                    onClick={() => onStatusChange('accepted')}
                    disabled
                  >
                    Accepteren
                  </Button>
                </Stack>
              }
            />
          )}

          {!blockedStatus && data.hour.statuses && (
            <DataList
              description={
                <>
                  {data.hour.statuses.map((status: any) => {
                    if (status.status === 'blocked') {
                      return
                    }

                    if (status.status === 'sent') {
                      return (
                        <Stack spacing="10px" key={`${status.status}-description`}>
                          <Chip
                            // key={`${status.status}-description`}
                            label={status.status_text}
                            color={status.status_class}
                            sx={{ mb: '10px' }}
                          />
                          {!invoiceWasViewed && (
                            <Chip
                              // key={`${status.status}-info`}
                              label={data.hour.concept_invoice_text}
                              color="warning"
                              sx={{ mb: '10px' }}
                            />
                          )}
                        </Stack>
                      )
                    }

                    return (
                      <Chip
                        key={`${status.status}-description`}
                        label={status.status_text}
                        color={status.status_class}
                        sx={{ mb: '10px' }}
                      />
                    )
                  })}
                </>
              }
              data={[]}
              button={
                <>
                  {data.hour.statuses.map((status: any) => {
                    if (status.status === 'accepted') {
                      return (
                        <Stack spacing="10px" key={`${status.status}-card-footer`}>
                          {data.hour.related_invoices.map((invoice: any) => {
                            return (
                              <Button
                                key={invoice.id}
                                fullWidth
                                variant="contained"
                                startIcon={<DownloadIcon />}
                                onClick={async () => {
                                  const accessToken = await authApi.getAccessToken()
                                  const data = await fetch(
                                    `${process.env.REACT_APP_API_URL}/invoice/${invoice.id}/download`,
                                    {
                                      method: 'GET',
                                      headers: {
                                        Authorization: `Bearer ${accessToken}`,
                                      },
                                    },
                                  )
                                  const documentBuffer = await data.arrayBuffer()
                                  var file = new Blob([documentBuffer], { type: 'application/pdf' })
                                  var fileURL = URL.createObjectURL(file)
                                  location.href = fileURL
                                }}
                              >
                                {invoice.invoice_type_name}
                              </Button>
                            )
                          })}
                        </Stack>
                      )
                    }
                    if (status.status === 'sent') {
                      return (
                        <Stack key={`${status.status}-card-footer`} spacing="10px" direction="column">
                          <Button
                            fullWidth
                            variant="contained"
                            startIcon={<DownloadIcon />}
                            endIcon={invoiceConceptLoading && <CircularProgress color="inherit" size={16} />}
                            onClick={async () => {
                              setInvoiceConceptLoading(true)
                              const accessToken = await authApi.getAccessToken()
                              const invoiceData = await fetch(
                                `${process.env.REACT_APP_API_URL}/invoice/${data.hour.id}/download/concept`,
                                {
                                  method: 'GET',
                                  headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                  },
                                },
                              )
                              const documentBuffer = await invoiceData.arrayBuffer()
                              var file = new Blob([documentBuffer], { type: 'application/pdf' })
                              var fileURL = URL.createObjectURL(file)
                              if (window.matchMedia('(display-mode: standalone)').matches) {
                                location.href = fileURL
                              } else {
                                window.open(fileURL, '_blank')
                                URL.revokeObjectURL(fileURL)
                              }
                              setInvoiceConceptLoading(false)
                              setInvoiceWasViewed(true)
                            }}
                          >
                            Invoice concept
                          </Button>
                          <Stack spacing="10px">
                            <Button
                              fullWidth
                              variant="contained"
                              startIcon={<CloseIcon />}
                              onClick={() => onStatusChange('rejected')}
                            >
                              Afkeuren
                            </Button>
                            <Button
                              fullWidth
                              variant="contained"
                              startIcon={<CheckIcon />}
                              onClick={() => onStatusChange('accepted')}
                              disabled={!invoiceWasViewed}
                            >
                              Accepteren
                            </Button>
                          </Stack>
                        </Stack>
                      )
                    }
                  })}
                </>
              }
            />
          )}
        </>
      )}
    </Layout>
  )
}

export default HoursItemPage
