import React, { BaseSyntheticEvent, ReactElement, useState } from 'react'
import { Box, FormGroup, TextField, Button, Link, Typography, CircularProgress } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import CheckboxInput from 'components/common/CheckboxInput/CheckboxInput'
import { authApi } from 'services/Auth'
import { config } from 'config'
import useNotification from 'hooks/useNotification'
import { URLSearchParams } from 'url'

export enum FormVariant {
  REGISTER = 'REGISTER',
  LOGIN = 'LOGIN',
}

export type LoginFormProps = {
  title: ReactElement
  subtitle: string
  variant: FormVariant
}

const LoginForm: React.FC<LoginFormProps> = ({ title, subtitle, variant }) => {
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      username: '',
      password: '',
      terms: false,
      stayLoggedIn: false,
    },
  })
  const [search] = useSearchParams()
  let navigate = useNavigate()
  const notify = useNotification()

  const onSubmit = async (data: any) => {
    if (!data.username || !data.password) {
      notify('error', 'U heeft één of meerder velden niet ingevuld. Vul alle velden in om in te loggen.')
      return
    }
    if (!data.terms) {
      notify('error', 'U dient akkoord te gaan met de Gebruikersvoorwaarden om in te kunnen loggen.')
      return
    }
    setLoading(true)
    const authRequest = await authApi.login({
      username: data.username,
      password: data.password,
      permanentLogin: data.stayLoggedIn,
    })
    if (authRequest.error) {
      notify(
        'error',
        authRequest.error?.message ?? 'U heeft één of meerdere velden onjuist ingevuld. Probeer nogmaals in te loggen.',
      )
    } else {
      const destination = search.get('d') ?? ''
      navigate(`/${destination}`)
    }
    setLoading(false)
  }

  return (
    <Box
      component="form"
      onSubmit={(e: BaseSyntheticEvent) => {
        handleSubmit(onSubmit)(e)
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'relative',
        pr: '10px',
        pl: '50px',
        '&:before': {
          content: '" "',
          width: '490px',
          height: '490px',
          background: (theme) => `${theme.palette.primary.main}DB`,
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          borderRadius: '50%',
          zIndex: 0,
        },
        '&:after': {
          content: '" "',
          width: '95px',
          height: '95px',
          background: (theme) => `${theme.palette.primary.main}90`,
          position: 'absolute',
          left: -50,
          bottom: '-40%',
          borderRadius: '50%',
        },
      }}
    >
      <Typography sx={{ zIndex: 1, fontSize: '20px', lineHeight: '27px' }} align="right" color="#AE9404">
        {subtitle}
      </Typography>
      <Typography
        sx={{ zIndex: 1, fontSize: '30px', lineHeight: '30px', fontWeight: 'bold', mb: 2 }}
        variant="h3"
        align="right"
      >
        {title}
      </Typography>
      <TextField placeholder="Gebruikersnaam" margin="dense" {...register('username')} />
      <TextField placeholder="Wachtwoord" margin="dense" type="password" {...register('password')} />
      {variant === FormVariant.LOGIN && (
        <FormGroup sx={{ width: '100%', mb: '18px' }}>
          <Controller
            name="terms"
            control={control}
            render={({ field }) => (
              <CheckboxInput
                {...field}
                color="secondary"
                label={
                  <Typography variant="caption" sx={{ fontSize: '11px', zIndex: 2 }}>
                    Ik ga akkoord met de{' '}
                    <Link
                      href={`${config.API_URL}/sites/default/files/SKVR%20Gebruiksvoorwaarden%20DocentenApp%202021_0.pdf`}
                      color="secondary.contrastText"
                      underline="always"
                    >
                      gebruikersvoorwaarden
                    </Link>
                  </Typography>
                }
              />
            )}
          />

          <Controller
            name="stayLoggedIn"
            control={control}
            render={({ field }) => (
              <CheckboxInput
                color="secondary"
                {...field}
                label={
                  <Typography variant="caption" sx={{ fontSize: '11px', zIndex: 2 }}>
                    Ik wil ingelogd blijven
                  </Typography>
                }
              />
            )}
          />
        </FormGroup>
      )}
      <Button
        variant="contained"
        type="submit"
        size="large"
        endIcon={loading && <CircularProgress color="inherit" size={16} />}
        disabled={loading}
        sx={{
          borderRadius: '6px',
          background: (theme) => theme.palette.primary.contrastText,
          color: 'white',
          fontWeight: 'bold',
          textTransform: 'none',
          fontSize: '14px',
          width: variant === FormVariant.LOGIN ? '125px' : '100%',
          height: '45px',
          mt: '7px',
          lineHeight: 1,
        }}
      >
        Login
      </Button>

      <Typography
        variant="caption"
        align={variant === FormVariant.LOGIN ? 'right' : 'center'}
        sx={{
          fontSize: '11px',
          zIndex: 2,
          mt: 3,
          mb: variant === FormVariant.LOGIN ? 0 : '40px',
          width: variant === FormVariant.LOGIN ? 'auto' : '100%',
        }}
      >
        <Link href={`/reset-password`} color="secondary.contrastText" underline="always">
          Je wachtwoord vergeten?
        </Link>
      </Typography>
    </Box>
  )
}

export default LoginForm
