import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as SendIcon } from 'assets/icons/send.svg'
import DataList from 'components/common/DataBox/DataBox'
import { Controller, useForm } from 'react-hook-form'
import TextInput from 'components/common/TextInput/TextInput'
import { required, maxLength } from '../validations'
import { useMutation } from '@apollo/client'
import { SEND_INVOICE } from 'graphql/queries'
import { GET_INVOICES } from 'graphql/queries'
import useNotification from 'hooks/useNotification'
import { authApi } from 'services/Auth'

type SendInvoiceFormProps = {
  invoice: any
  loadedInvoices: number
}

const SendInvoiceForm: React.FC<SendInvoiceFormProps> = ({ invoice, loadedInvoices }) => {
  const [open, openModal] = useState(false)
  const notify = useNotification()

  const [sendInvoice, { data, loading }] = useMutation(SEND_INVOICE)

  const { handleSubmit, control, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      invoice_code: '',
    },
  })

  const { isValid, isSubmitting, errors } = formState

  const onSubmit = (data: any) => {
    sendInvoice({
      variables: {
        invoice_id: invoice.id.toString(),
        new_state: 'sent',
        invoice_code: data.invoice_code,
      },
      refetchQueries: [
        {
          query: GET_INVOICES,
          variables: { professional_id: authApi.getUserId().toString(), limit: loadedInvoices.toString() },
        },
      ],
      onCompleted: (data) => {
        console.log(data)
        if (data?.invoiceStateChange?.status) {
          notify('success', 'Invoice sent successfully.')
          reset()
          openModal(false)
        } else {
          notify('error', data?.invoiceStateChange?.errors[0]?.message)
        }
      },
      awaitRefetchQueries: true,
    })
  }

  return (
    <>
      <Dialog open={open} fullWidth onClose={() => openModal(false)}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {`Factuur ${invoice.related_hour.month}/${invoice.related_hour.year}`}
          <IconButton aria-label="close" onClick={() => openModal(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: '15px' }}>
            <Chip
              label="Weet u zeker dat u onderstaande factuur definitief wilt maken en verzenden? Controleer het factuurnummer goed. U kunt het factuurnummer hierna NIET meer wijzigen!"
              color="warning"
              sx={{ mb: '10px' }}
            />
            <Box component="form">
              <Controller
                name="invoice_code"
                control={control}
                rules={{
                  ...required(),
                  maxLength: maxLength(20, 'Het facturnummer mag niet langer zijn dat 20 karakters.'),
                  validate: {
                    beginsWithZero: (value: string) => {
                      return value.at(0) !== '0' || 'Het factuurnummer kan niet met nul beginnen.'
                    },
                  },
                }}
                render={({ field }) => (
                  <TextInput error={errors?.invoice_code?.message} label="Factuurnummer" required {...field} />
                )}
              />
            </Box>
            <Stack direction="row" justifyContent="end" my="10px">
              <Button
                variant="contained"
                fullWidth
                type="submit"
                disabled={!isValid || isSubmitting}
                onClick={handleSubmit(onSubmit)}
                endIcon={loading && <CircularProgress color="inherit" size={16} />}
              >
                Factuur verzenden
              </Button>
            </Stack>
            <DataList
              data={[
                { key: 'Type:', value: invoice.invoice_type_name },
                {
                  key: 'Totaal Bedrag:',
                  value: `€${invoice.totals
                    .reduce((acc: number, amount: any) => acc + Number(amount.display_amount.replace(',', '.')), 0)
                    .toFixed(2)}`,
                },
                { key: 'SKVR ID:', value: invoice.skvr_id },
              ]}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <Button fullWidth variant="outlined" startIcon={<SendIcon />} onClick={() => openModal(true)}>
        Send
      </Button>
    </>
  )
}

export default SendInvoiceForm
