import React, { useEffect, useCallback } from 'react'
import Layout from 'components/Layout/Layout'
import { Button, Typography, Stack, Pagination } from '@mui/material'
import { useQuery } from '@apollo/client'
import { GET_HOURS } from 'graphql/queries'
import DataList from 'components/common/DataBox/DataBox'
import moment from 'moment'
import 'moment/locale/nl'
import { useNavigate, useLocation } from 'react-router-dom'
import FilterHoursForm from 'components/forms/FilterHoursForm/FilterHoursForm'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { ReactComponent as DocumentIcon } from 'assets/icons/documents.svg'

const projectHoursPage: React.FC<{ showBottomNavigation?: boolean }> = ({ showBottomNavigation }) => {
  const navigate = useNavigate()
  const { hash } = useLocation()

  const { loading, data, refetch, fetchMore } = useQuery(GET_HOURS, {
    variables: {
      current_page: '0',
    },
    notifyOnNetworkStatusChange: true,
  })

  const paginationHandler = useCallback((event: React.ChangeEvent<unknown>, value: any) => {
    refetch({
      current_page: `${value - 1}`,
    })
  }, [])

  useEffect(() => {
    if (!loading && hash) {
      let hours = document.querySelector(`#hours-${hash.slice(1)}`)
      hours?.scrollIntoView(true)
    } else {
      window.scrollTo({ top: 0, left: 0 })
    }
  }, [loading])

  return (
    <Layout showBottomNavigation={showBottomNavigation} loading={loading}>
      <Typography variant="h1">Urenoverzicht</Typography>

      <FilterHoursForm
        years={data?.hours?.available_years ?? []}
        onFilter={(data: any) =>
          refetch({
            current_page: '0',
            ...data,
          })
        }
      />

      {data?.hours?.items?.map((projectHours: any) => {
        return (
          <Stack spacing="10px" key={projectHours.id}>
            <Typography variant="h2" textTransform="capitalize">
              {moment()
                .locale('nl')
                .month(projectHours.month - 1)
                .format('MMMM')}{' '}
              {projectHours.year}
            </Typography>

            {projectHours.project_hours.map((project: any) => {
              return (
                <DataList
                  key={project.id}
                  id={`hours-${projectHours.id}-${project.id}`}
                  title={
                    <>
                      Opdracht
                      <br />
                      <Typography component="span" fontWeight={300}>
                        {project.related_project.profile.name}
                        {` (${project.related_project.code})`}
                      </Typography>
                    </>
                  }
                  data={[
                    {
                      key: 'Omschrijving:',
                      value: project.related_project.title,
                    },
                    {
                      key: 'Tarief lesgeb.',
                      value: `€${project.related_project.rate}`,
                    },
                    {
                      key: 'Tarief niet lesgeb.',
                      value: `€${project.related_project.rate_other}`,
                    },
                    {
                      key: 'Start:',
                      value: moment(project.related_project.start_date).format('DD-MM-YYYY'),
                    },
                    {
                      key: 'Eind:',
                      value: moment(project.related_project.end_date).format('DD-MM-YYYY'),
                    },
                  ]}
                  status={
                    projectHours?.statuses && {
                      text: projectHours?.statuses[0]?.status_text,
                      color: projectHours?.statuses[0]?.status_class,
                    }
                  }
                  button={
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => navigate(`/hours/${projectHours.id}#${projectHours.id}-${project.id}`)}
                    >
                      Uren
                    </Button>
                  }
                />
              )
            })}
          </Stack>
        )
      })}
      {data?.hours?.pages_total > 0 && (
        <Stack>
          <Pagination
            page={data?.hours?.current_page + 1}
            count={data?.hours?.pages_total + 1}
            shape="rounded"
            size="medium"
            onChange={paginationHandler}
          />
        </Stack>
      )}

      {data?.hours?.items.length === 0 && (
        <Typography variant="body1">
          Geen resultaten gevonden. Pas uw zoekopdracht aan of reset de ingestelde filters
        </Typography>
      )}

      <Typography variant="h2">Betaalkalender</Typography>

      {data?.payment_calendars.map((doc: any) => {
        return (
          <DataList
            key={doc.payment_calendar_file.id}
            title={doc.payment_calendar_file.name}
            data={[
              {
                key: 'Upload Datum:',
                value: doc?.payment_calendar_file?.created
                  ? moment(doc?.payment_calendar_file?.created).format('DD-MM-YYYY')
                  : 'n.v.t',
              },
            ]}
            icon={<DocumentIcon />}
            button={
              <Button
                fullWidth
                variant="contained"
                startIcon={<DownloadIcon />}
                href={doc?.payment_calendar_file?.download_link}
                target="_blank"
                download
              >
                Download
              </Button>
            }
          />
        )
      })}
    </Layout>
  )
}

export default projectHoursPage
