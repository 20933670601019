import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { Box, Chip, Paper, Table, TableBody, TableCell, TableRow, Typography, Stack, Button } from '@mui/material'
import React, { ReactComponentElement, ReactElement } from 'react'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import FileInput from 'components/common/FileInput/FileInput'

type DataListProps = {
  title?: ReactJSXElement | string
  description?: string | ReactJSXElement
  data: any
  button?: ReactJSXElement
  icon?: ReactElement
  status?: {
    color?: 'error' | 'success'
    text: string
  }
  downloadLink?: string
  id?: string
}

const DataBox: React.FC<DataListProps> = ({ title, description, data, icon, button, status, downloadLink, id }) => {
  return (
    <Paper variant="outlined" component={Stack} spacing="20px" id={id}>
      <Box>
        <Stack direction="row">
          {icon && <Box sx={{ marginRight: '20px' }}>{icon}</Box>}
          {title && (
            <Typography fontWeight="bold" sx={{ flexGrow: 0 }}>
              {downloadLink ? (
                <a href={downloadLink} style={{ color: 'black' }} download target="_blank">
                  {title}
                </a>
              ) : (
                title
              )}
            </Typography>
          )}
        </Stack>

        {description && description}
      </Box>
      <Table sx={{ marginTop: '0!important' }}>
        <TableBody>
          {data.map((row: any) => {
            return (
              <TableRow key={row.key}>
                <TableCell sx={{ padding: '8px 0!important', minWidth: '50%' }}>{row.key}</TableCell>
                <TableCell sx={{ padding: '8px 0!important', maxWidth: '50%', width: '50%' }}>{row.value}</TableCell>
              </TableRow>
            )
          })}
          {status && (
            <TableRow>
              <TableCell sx={{ padding: '8px 0!important' }}>Status</TableCell>
              <TableCell sx={{ padding: '8px 0!important' }}>
                <Chip label={status.text} color={status.color} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {button && button}
    </Paper>
  )
}

export default DataBox
