import React, { useId } from 'react'
import { Box, InputLabel, MenuItem, TextField } from '@mui/material'

interface SelectInputProps {
  label?: string
  innerLabel?: string
  required?: boolean
  options: {
    tid: string
    name: string
  }[]
  error?: string
  [prop: string]: any
}

const SelectInput: React.FC<SelectInputProps> = React.forwardRef((props, ref) => {
  const { label, required = false, innerLabel, options, error, ...rest } = props
  const id = useId()
  return (
    <Box>
      {label && !innerLabel && (
        <InputLabel
          required={required}
          htmlFor={id}
          sx={{
            fontSize: '14px',
            lineHeight: '21px',
            mb: '2px',
            '& .MuiInputLabel-asterisk': { color: (theme) => theme.palette.error.main },
          }}
        >
          {label}
        </InputLabel>
      )}
      <TextField
        select
        id={id}
        variant="outlined"
        fullWidth
        required={required}
        label={innerLabel}
        {...rest}
        defaultValue={rest.multiple ? [] : ''}
        error={!!error}
        helperText={error}
      >
        {options.map((option) => (
          <MenuItem value={option.tid} key={option.tid}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  )
})

export default SelectInput
