import { Alert, Snackbar } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { NotificationsContext } from 'App'
import { useLocation } from 'react-router-dom'

const NotificationSnackbar: React.FC = () => {
  const { notifications, updateNotifications } = useContext(NotificationsContext)
  const { pathname } = useLocation()

  const closeNotification = (id: string) => {
    updateNotifications(notifications.filter((notification) => notification.id !== id))
  }

  useEffect(() => {
    updateNotifications([])
  }, [pathname])

  return (
    <>
      {notifications.map((notification, i) => (
        <Snackbar
          key={notification.id}
          open={true}
          autoHideDuration={6000}
          onClose={() => closeNotification(notification.id)}
          ClickAwayListenerProps={{ onClickAway: () => false }}
          disableWindowBlurListener={true}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{ top: `${85 + i * 50}px` }}
        >
          <Alert onClose={() => closeNotification(notification.id)} severity={notification.type} sx={{ width: '100%' }}>
            {notification.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  )
}

export default NotificationSnackbar
