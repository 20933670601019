import React, { useState, PropsWithChildren, ReactElement, useEffect } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Typography, Stack } from '@mui/material'
import { ReactComponent as SpoilerIcon } from 'assets/icons/spoiler-icon.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg'

export enum SpoilerVariant {
  default = 'default',
  secondary = 'secondary',
}

const variantStyles = {
  default: {
    root: {
      margin: '10px 0',
    },
    summary: {},
    body: {},
  },
  secondary: {
    root: {
      '&.Mui-expanded': {
        margin: 0,
      },
    },
    summary: {
      background: 'transparent',
      height: 'auto',
      padding: 0,
      '&.Mui-expanded': {
        background: 'transparent',
        minHeight: 'unset',
        margin: 0,
      },
      '& .MuiAccordionSummary-content': {
        justifyContent: 'flex-start',
        // gap: '10px',
        '& > svg:first-of-type': {
          marginRight: '10px',
        },
        '&.Mui-expanded': {
          margin: '12px 0',
        },
      },
      '& .MuiAccordionSummary-expandIconWrapper': {
        width: '29px',
        height: '29px',
        borderRadius: '50%',
        background: '#272525',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    body: { padding: '15px 30px' },
  },
}

const Spoiler: React.FC<
  PropsWithChildren<{
    title: string
    variant?: SpoilerVariant
    icon?: ReactElement
    open?: boolean
    forceOpen?: boolean
  }>
> = ({ title, children, variant = SpoilerVariant.default, icon, open = false, forceOpen = false }) => {
  const [expanded, setExpanded] = useState(open || forceOpen)

  useEffect(() => {
    setExpanded(open)
  }, [open])

  useEffect(() => {
    if (forceOpen) {
      setExpanded(true)
    }
  }, [forceOpen])
  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={variantStyles[variant]?.root}
    >
      <AccordionSummary
        aria-controls={title}
        id={title}
        expandIcon={variant === SpoilerVariant.default ? <SpoilerIcon /> : expanded ? <MinusIcon /> : <PlusIcon />}
        sx={variantStyles[variant]?.summary}
      >
        {variant === SpoilerVariant.secondary && icon}
        <Typography sx={{ fontWeight: 300 }}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={variantStyles[variant]?.body}>
        {children}
        {variant === SpoilerVariant.default && (
          <Stack direction="row-reverse">
            <IconButton
              color="primary"
              size="large"
              aria-label="close"
              sx={{
                boxShadow: 'none',
                width: '45px',
                height: '45px',
                mt: '10px',
                background: (theme) => theme.palette.secondary.main,
              }}
              onClick={() => setExpanded(!expanded)}
            >
              <SpoilerIcon style={{ transform: 'rotate(180deg)' }} />
            </IconButton>
          </Stack>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default Spoiler
