import { Backdrop, Box, CircularProgress, Container, Grow, Stack } from '@mui/material'
import React, { PropsWithChildren, useEffect } from 'react'
import TopBar from 'components/Layout/AppBar/AppBar'
import NavigationBar from './NavigationBar/NavigationBar'
import { useNavigate, Navigate } from 'react-router-dom'

const Layout: React.FC<PropsWithChildren<{ showBottomNavigation?: boolean; loading?: boolean }>> = ({
  children,
  showBottomNavigation = true,
  loading = false,
}) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (!navigator.onLine) {
      navigate('/error')
    }
  }, [])
  return (
    <Box>
      <TopBar />
      <Grow in mountOnEnter unmountOnExit>
        <Container sx={{ pl: '25px', pr: '25px', pb: '100px' }}>
          <Stack direction="column" spacing="40px">
            {children}
          </Stack>
        </Container>
      </Grow>
      {showBottomNavigation && <NavigationBar />}
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
        open={loading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Box>
  )
}

export default Layout
