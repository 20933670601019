import React, { useEffect } from 'react'
import { Paper, Stack, Box, Button } from '@mui/material'
import SelectInput from 'components/common/SelectInput/SelectInput'
import { useForm, Controller } from 'react-hook-form'

type filtersType = {
  month?: string
  year?: string
}

const months = [
  { tid: 'All', name: '- Kies maand -' },
  { tid: '1', name: 'Januari' },
  { tid: '2', name: 'Februari' },
  { tid: '3', name: 'Maart' },
  { tid: '4', name: 'April' },
  { tid: '5', name: 'Mei' },
  { tid: '6', name: 'Juni' },
  { tid: '7', name: 'Juli' },
  { tid: '8', name: 'Augustus' },
  { tid: '9', name: 'September' },
  { tid: '10', name: 'Oktober' },
  { tid: '11', name: 'November' },
  { tid: '12', name: 'December' },
]

const FilterHoursForm: React.FC<{ onFilter: (data: filtersType) => void; years: string[] }> = ({ onFilter, years }) => {
  const { control, handleSubmit, reset, formState } = useForm({
    defaultValues: {
      month: 'All',
      year: 'All',
    },
  })

  const yearsOptions = [...years].map((year) => {
    return { tid: year, name: year }
  })

  const { errors } = formState

  const onSubmit = (data: any) => {
    const filters: filtersType = {}
    filters.month = data.month === 'All' ? '' : data.month.toString()
    filters.year = data.year === 'All' ? '' : data.year.toString()
    onFilter(filters)
  }

  return (
    <Paper variant="outlined">
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack direction="column" spacing="10px">
          <Controller
            name="year"
            control={control}
            render={({ field }) => (
              <SelectInput
                error={errors?.year?.message}
                options={[{ tid: 'All', name: '- Kies jaar -' }, ...yearsOptions]}
                {...field}
              />
            )}
          />
          <Controller
            name="month"
            control={control}
            render={({ field }) => <SelectInput error={errors?.month?.message} options={months} {...field} />}
          />
          <Stack direction="row" spacing="10px">
            <Button variant="contained" sx={{ flexGrow: 1 }} type="submit">
              Filter
            </Button>
            <Button
              variant="outlined"
              sx={{ flexGrow: 1 }}
              type="reset"
              onClick={() => {
                reset()
                onFilter({ month: 'All', year: 'All' })
              }}
            >
              Reset
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  )
}

export default FilterHoursForm
