import React from 'react'
import { ReactComponent as BugIcon } from 'assets/icons/bug-solid.svg'
import { Button, Typography, Stack, Container, Grow } from '@mui/material'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Grow in mountOnEnter unmountOnExit>
          <Container sx={{ pl: '25px', pr: '25px', height: '100vh' }}>
            <Stack direction="column" spacing="40px" justifyContent="center" sx={{ height: '100vh' }}>
              <Typography variant="h2" align="center">
                Something went wrong. Please try again later.
              </Typography>
              <BugIcon style={{ width: '20%', margin: '40px auto 0' }} />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  window.history.back()
                  this.setState({ hasError: false })
                }}
              >
                Go Back
              </Button>
            </Stack>
          </Container>
        </Grow>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
