import React, { useEffect } from 'react'
import { Paper, Stack, Box, Button } from '@mui/material'
import SelectInput from 'components/common/SelectInput/SelectInput'
import { useForm, Controller } from 'react-hook-form'

type filtersType = {
  invoice_type?: string
  state?: string
  month?: string
  year?: string
}

const invoicesTypes = [
  { tid: 'All', name: '- Kies type -' },
  { tid: 'declaration_vat_free', name: 'Decl. BTW vrijgesteld' },
  { tid: 'hours_vat_free', name: 'Uren BTW vrijgesteld' },
  { tid: 'hours_vat', name: 'Uren BTW 21%' },
  { tid: 'combined', name: 'SKVR Factuur' },
]

const invoicesStatuses = [
  { tid: 'All', name: '- Kies status -' },
  { tid: 'draft', name: 'Concept' },
  { tid: 'sent', name: 'Verzonden' },
  { tid: 'paid', name: 'Betaald' },
]

const months = [
  { tid: 'All', name: '- Kies maand -' },
  { tid: '1', name: 'Januari' },
  { tid: '2', name: 'Februari' },
  { tid: '3', name: 'Maart' },
  { tid: '4', name: 'April' },
  { tid: '5', name: 'Mei' },
  { tid: '6', name: 'Juni' },
  { tid: '7', name: 'Juli' },
  { tid: '8', name: 'Augustus' },
  { tid: '9', name: 'September' },
  { tid: '10', name: 'Oktober' },
  { tid: '11', name: 'November' },
  { tid: '12', name: 'December' },
]

const FilterInvoicesForm: React.FC<{ onFilter: (data: filtersType) => void; availableYears: string[] }> = ({
  onFilter,
  availableYears,
}) => {
  const { control, handleSubmit, reset, formState } = useForm({
    defaultValues: {
      state: 'All',
      invoice_type: 'All',
      month: 'All',
      year: 'All',
    },
  })

  const years = availableYears.map((year) => {
    return { tid: `${year}`, name: year }
  })

  const { errors } = formState

  const onSubmit = (data: any) => {
    const filters: filtersType = {}
    filters.state = data.state === 'All' ? '' : data.state
    filters.invoice_type = data.invoice_type === 'All' ? '' : data.invoice_type
    filters.month = data.month === 'All' ? '' : data.month
    filters.year = data.year === 'All' ? '' : data.year

    onFilter(filters)
  }

  return (
    <Paper variant="outlined">
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack direction="column" spacing="10px">
          <Controller
            name="invoice_type"
            control={control}
            render={({ field }) => (
              <SelectInput error={errors?.invoice_type?.message} options={invoicesTypes} {...field} />
            )}
          />
          <Controller
            name="state"
            control={control}
            render={({ field }) => <SelectInput error={errors?.state?.message} options={invoicesStatuses} {...field} />}
          />
          <Controller
            name="month"
            control={control}
            render={({ field }) => <SelectInput error={errors?.month?.message} options={months} {...field} />}
          />
          <Controller
            name="year"
            control={control}
            render={({ field }) => (
              <SelectInput
                error={errors?.year?.message}
                options={[{ tid: 'All', name: '- Kies jaar -' }, ...years]}
                {...field}
              />
            )}
          />
          <Stack direction="row" spacing="10px">
            <Button variant="contained" sx={{ flexGrow: 1 }} type="submit">
              Filter
            </Button>
            <Button
              variant="outlined"
              sx={{ flexGrow: 1 }}
              type="reset"
              onClick={() => {
                reset()
                onFilter({ state: 'All', invoice_type: 'All', month: 'All', year: 'All' })
              }}
            >
              Reset
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  )
}

export default FilterInvoicesForm
