import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client'
import { config } from 'config'
import { authApi } from 'services/Auth'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

const httpLink = new HttpLink({ uri: config.API_URL + '/graphql' })

const authMiddleware = setContext(async (_, { headers }) => {
  const token = await authApi.getAccessToken()
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  }
})

const errorMiddleware = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    )
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
    // authApi.logout()
  }
})

const client = new ApolloClient({
  link: from([authMiddleware, errorMiddleware, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
    },
  },
})

export default client
