import React, { BaseSyntheticEvent, useState } from 'react'
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { config } from 'config'
import useNotification from 'hooks/useNotification'

const ResetPasswordForm = () => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: '',
    },
  })
  let navigate = useNavigate()
  const notify = useNotification()

  const onSubmit = async (data: any) => {
    if (!data.email) {
      notify('error', 'Voer je e-mailadres in om je wachtwoord opnieuw in te stellen.')
      return
    }
    setLoading(true)
    const resetPasswordRequest = await fetch(`${config.API_URL}/user/lost-password?_format=json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        mail: data.email,
      }),
    })

    setLoading(false)
    setSuccess(true)
  }

  return (
    <Box
      component="form"
      onSubmit={(e: BaseSyntheticEvent) => {
        handleSubmit(onSubmit)(e)
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'relative',
        pr: '10px',
        pl: '50px',
        '&:before': {
          content: '" "',
          width: '490px',
          height: '490px',
          background: (theme) => `${theme.palette.primary.main}DB`,
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          borderRadius: '50%',
          zIndex: 0,
        },
        '&:after': {
          content: '" "',
          width: '95px',
          height: '95px',
          background: (theme) => `${theme.palette.primary.main}90`,
          position: 'absolute',
          left: -50,
          bottom: '-100%',
          borderRadius: '50%',
        },
      }}
    >
      <Typography
        sx={{ zIndex: 1, fontSize: '30px', lineHeight: '30px', fontWeight: 'bold', mb: 2 }}
        variant="h3"
        align="right"
      >
        Wachtwoord resetten
      </Typography>
      {success ? (
        <>
          <Typography variant="body1" align="right" sx={{ zIndex: 1, mb: 2, maxWidth: '290px' }}>
            Nadere instructies zijn naar uw e-mailadres gestuurd.
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate('/login')}
            sx={{
              borderRadius: '6px',
              background: (theme) => theme.palette.primary.contrastText,
              color: 'white',
              fontWeight: 'bold',
              textTransform: 'none',
              fontSize: '14px',
              // width: '125px',
              height: '45px',
              mt: '7px',
              lineHeight: 1,
            }}
          >
            Login
          </Button>
        </>
      ) : (
        <>
          <TextField placeholder="E-mailadres" margin="dense" {...register('email')} />

          <Button
            variant="contained"
            type="submit"
            size="large"
            endIcon={loading && <CircularProgress color="inherit" size={16} />}
            disabled={loading}
            sx={{
              borderRadius: '6px',
              background: (theme) => theme.palette.primary.contrastText,
              color: 'white',
              fontWeight: 'bold',
              textTransform: 'none',
              fontSize: '14px',
              // width: '125px',
              height: '45px',
              mt: '7px',
              lineHeight: 1,
            }}
          >
            Wachtwoord resetten
          </Button>
        </>
      )}
    </Box>
  )
}

export default ResetPasswordForm
