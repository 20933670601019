import React from 'react'
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { NavigationRoutes } from 'pages/routes'

const NavigationBar: React.FC = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, left: 0, width: '100%', zIndex: (theme) => theme.zIndex.drawer + 2 }}
      elevation={0}
    >
      <BottomNavigation
        showLabels
        value={'/' + pathname.split('/')[1]}
        onChange={(_e, newPath) => {
          navigate(newPath)
        }}
        sx={{ height: '75px', background: (theme) => theme.palette.secondary.main }}
      >
        {NavigationRoutes.map((route) => (
          <BottomNavigationAction key={route.route} label={route.title} icon={route.icon} value={route.route} />
        ))}
      </BottomNavigation>
    </Paper>
  )
}

export default NavigationBar
