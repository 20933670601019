import React, { useEffect } from 'react'
import Layout from 'components/Layout/Layout'
import { useNavigate } from 'react-router-dom'
import { Button, Typography } from '@mui/material'
import { ReactComponent as WifiIcon } from 'assets/icons/wifi-solid.svg'

const ErrorPage: React.FC<{ showBottomNavigation?: boolean }> = ({ showBottomNavigation }) => {
  const navigate = useNavigate()
  return (
    <Layout showBottomNavigation={showBottomNavigation} loading={false}>
      <Typography variant="h2" align="center">
        No Internet connection. Please check your connectivity and reload the page.
      </Typography>
      <WifiIcon style={{ width: '20%', margin: '40px auto 0' }} />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          navigate('/')
        }}
      >
        Reload
      </Button>
    </Layout>
  )
}

export default ErrorPage
