import React from 'react'
import Layout from 'components/Layout/Layout'
import { Typography } from '@mui/material'

const FallbackPage: React.FC<{ showBottomNavigation?: boolean }> = ({ showBottomNavigation }) => {
  return (
    <Layout showBottomNavigation={showBottomNavigation}>
      <Typography variant="h1">Page not found</Typography>
    </Layout>
  )
}

export default FallbackPage
