import React from 'react'
import Layout from 'components/Layout/Layout'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Link,
  Chip,
  Button,
  Stack,
} from '@mui/material'
import { useQuery, useMutation } from '@apollo/client'
import { GET_PROJECT } from 'graphql/queries'
import DataTable from 'components/common/DataTable/DataTable'
import moment from 'moment'
import Spoiler from 'components/common/Spoiler/Spoiler'
import DataList from 'components/common/DataBox/DataBox'
// import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close_alt.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'
import TextInput from 'components/common/TextInput/TextInput'
import { useForm, Controller } from 'react-hook-form'
import { CHANGE_PROJECT_STATE, GET_PROFESSIONAL } from 'graphql/queries'
import { authApi } from 'services/Auth'

const days: { [index: number]: string } = {
  1: 'Ma.',
  2: 'Di.',
  3: 'Wo.',
  4: 'Do.',
  5: 'Vr.',
  6: 'Za.',
  7: 'Zo.',
}

const ProjectItemPage: React.FC<{ showBottomNavigation?: boolean }> = ({ showBottomNavigation }) => {
  const { projectId } = useParams()
  const navigate = useNavigate()
  // const { pathname, hash } = useLocation()
  const { data, loading } = useQuery(GET_PROJECT, { variables: { id: Number(projectId) } })
  const [changeProjectStatus, { data: statusChangeData, loading: statusChangeLoading, error: statusChangeError }] =
    useMutation(CHANGE_PROJECT_STATE)
  const { getValues, control } = useForm()

  const DataBody = () => {
    return (
      <>
        {data?.project?.week_planning.map((week: { week_day_id: number; lessons: number; non_class: number }) => (
          <TableRow key={week.week_day_id}>
            <TableCell component="th" scope="row">
              {days[week.week_day_id]}
            </TableCell>
            <TableCell>{week.lessons.toFixed(2)}</TableCell>
            <TableCell>{week.non_class.toFixed(2)}</TableCell>
          </TableRow>
        ))}
      </>
    )
  }

  const onStatusChange = (newStatus: string) => {
    const note = getValues('note')

    const payload = {
      project_id: projectId,
      new_state: newStatus,
      professional_note: note,
    }
    changeProjectStatus({
      variables: payload,
      awaitRefetchQueries: true,
      onCompleted: () => {
        navigate(`/project#${projectId}`)
      },
      refetchQueries: [
        { query: GET_PROJECT, variables: { id: Number(projectId) } },
        {
          query: GET_PROFESSIONAL,
          variables: {
            id: authApi.getUserId(),
          },
        },
      ],
    })
  }

  return (
    <Layout showBottomNavigation={showBottomNavigation} loading={loading || statusChangeLoading}>
      <Box>
        <Typography variant="h2" fontSize={24} display="inline" fontWeight={300} sx={{ marginRight: '5px' }}>
          Titel:
        </Typography>
        <Typography variant="h1" display="inline" sx={{ wordBreak: 'break-word' }}>
          {loading ? <Skeleton /> : data?.project?.title}
        </Typography>
      </Box>
      {loading && (
        <Box>
          <Skeleton variant="rectangular" sx={{ width: '100%', height: '75px', borderRadius: '6px', mb: '10px' }} />
          <Skeleton variant="rectangular" sx={{ width: '100%', height: '75px', borderRadius: '6px', mb: '10px' }} />
          <Skeleton variant="rectangular" sx={{ width: '100%', height: '75px', borderRadius: '6px', mb: '10px' }} />
        </Box>
      )}
      {!loading && (
        <>
          <Box>
            <Spoiler title="Opdrachtgegevens" open={true}>
              <Table>
                <TableBody>
                  {data?.project?.title && (
                    <TableRow>
                      <TableCell sx={{ padding: '8px 0!important', fontWeight: 500 }}>Titel:</TableCell>
                      <TableCell sx={{ padding: '8px 0!important' }}>{data?.project?.title}</TableCell>
                    </TableRow>
                  )}
                  {data?.project?.code && (
                    <TableRow>
                      <TableCell sx={{ padding: '8px 0!important', fontWeight: 500 }}>Code:</TableCell>
                      <TableCell sx={{ padding: '8px 0!important' }}>{data?.project?.code}</TableCell>
                    </TableRow>
                  )}
                  {data?.project?.assigned_user?.full_name && (
                    <TableRow>
                      <TableCell sx={{ padding: '8px 0!important', fontWeight: 500 }}>Professional:</TableCell>
                      <TableCell sx={{ padding: '8px 0!important' }}>
                        {data?.project?.assigned_user?.full_name}
                      </TableCell>
                    </TableRow>
                  )}
                  {data?.project?.id && (
                    <TableRow>
                      <TableCell sx={{ padding: '8px 0!important', fontWeight: 500 }}>Opdrachtnummer:</TableCell>
                      <TableCell sx={{ padding: '8px 0!important' }}>{data?.project?.id}</TableCell>
                    </TableRow>
                  )}
                  {data?.project?.description && (
                    <TableRow>
                      <TableCell sx={{ padding: '8px 0!important', fontWeight: 500 }}>Omschrijving:</TableCell>
                      <TableCell sx={{ padding: '8px 0!important' }}>{data?.project?.description}</TableCell>
                    </TableRow>
                  )}
                  {data?.project?.profile.name && (
                    <TableRow>
                      <TableCell sx={{ padding: '8px 0!important', fontWeight: 500 }}>Profiel categorie:</TableCell>
                      <TableCell sx={{ padding: '8px 0!important' }}>{data?.project?.profile.name}</TableCell>
                    </TableRow>
                  )}
                  {data?.project?.costcenter.name && (
                    <TableRow>
                      <TableCell sx={{ padding: '8px 0!important', fontWeight: 500 }}>Kostenplaatsen:</TableCell>
                      <TableCell sx={{ padding: '8px 0!important' }}>{data?.project?.costcenter.name}</TableCell>
                    </TableRow>
                  )}
                  {data.project.start_date && (
                    <TableRow>
                      <TableCell sx={{ padding: '8px 0!important', fontWeight: 500 }}>Startdatum:</TableCell>
                      <TableCell sx={{ padding: '8px 0!important' }}>
                        {moment(data.project.start_date)?.format('DD-MM-YYYY')}
                      </TableCell>
                    </TableRow>
                  )}
                  {data.project.end_date && (
                    <TableRow>
                      <TableCell sx={{ padding: '8px 0!important', fontWeight: 500 }}>Einddatum:</TableCell>
                      <TableCell sx={{ padding: '8px 0!important' }}>
                        {moment(data.project.end_date)?.format('DD-MM-YYYY')}
                      </TableCell>
                    </TableRow>
                  )}
                  {data?.project?.rate && (
                    <TableRow>
                      <TableCell sx={{ padding: '8px 0!important', fontWeight: 500 }}>
                        Tarief Lesgebonden (€, per uur):
                      </TableCell>
                      <TableCell sx={{ padding: '8px 0!important' }}>{data?.project?.rate}</TableCell>
                    </TableRow>
                  )}
                  {data?.project?.rate_other && (
                    <TableRow>
                      <TableCell sx={{ padding: '8px 0!important', fontWeight: 500 }}>
                        Tarief Niet Lesgebonden (€, per uur)
                      </TableCell>
                      <TableCell sx={{ padding: '8px 0!important' }}>{data?.project?.rate_other}</TableCell>
                    </TableRow>
                  )}
                  {data?.project?.commute_distance && (
                    <TableRow>
                      <TableCell sx={{ padding: '8px 0!important', fontWeight: 500 }}>
                        Woon-werkverkeer (auto) per dag (km):
                      </TableCell>
                      <TableCell sx={{ padding: '8px 0!important' }}>
                        {data?.project?.commute_distance?.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Spoiler>

            <Spoiler title="Contactpersoon">
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ padding: '8px 0!important', fontWeight: 500 }}>Naam:</TableCell>
                    <TableCell sx={{ padding: '8px 0!important' }}>
                      {data?.project?.project_manager.full_name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: '8px 0!important', fontWeight: 500 }}>Functie:</TableCell>
                    <TableCell sx={{ padding: '8px 0!important' }}>{data?.project?.project_manager.function}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: '8px 0!important', fontWeight: 500 }}>Telefoon:</TableCell>
                    <TableCell sx={{ padding: '8px 0!important' }}>{data?.project?.project_manager.tel_work}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: '8px 0!important', fontWeight: 500 }}>Email:</TableCell>
                    <TableCell sx={{ padding: '8px 0!important' }}>
                      <Link
                        href={`mailto:${data?.project?.project_manager.email_work}`}
                        color="primary.contrastText"
                        target="_blank"
                      >
                        {data?.project?.project_manager.email_work}
                      </Link>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Spoiler>

            <Spoiler title="Planning">
              <DataTable head={['Dag', 'Lesgeb.', 'Niet Lesgeb.']} body={<DataBody />} />
            </Spoiler>
          </Box>
          {data.project.state === 'sent' ? (
            <Stack spacing="20px">
              <Typography variant="h2">DEZE OPDRACHT ACCEPTEREN?</Typography>
              <Controller
                name="note"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextInput
                    label="Bericht"
                    multiline={true}
                    rows={5}
                    disabled={data.project.state !== 'sent'}
                    {...field}
                  />
                )}
              />
              <DataList
                description=""
                data={[]}
                button={
                  <Stack spacing="10px">
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={<CloseIcon />}
                      onClick={() => onStatusChange('rejected')}
                    >
                      Afkeuren
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={<CheckIcon />}
                      onClick={() => onStatusChange('accepted')}
                    >
                      Accepteren
                    </Button>
                  </Stack>
                }
              />
            </Stack>
          ) : (
            ''
          )}
        </>
      )}
    </Layout>
  )
}

export default ProjectItemPage
