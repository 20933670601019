import React from 'react'
import { ReactComponent as DocumentIcon } from 'assets/icons/documents.svg'
import { ReactComponent as TaskIcon } from 'assets/icons/tasks.svg'
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg'
import { ReactComponent as WalletIcon } from 'assets/icons/wallet.svg'

export type RouteType = {
  title: string
  route: string
  icon?: React.ReactElement
  badge?: string | boolean
  badgeId: string
}

export const NavigationRoutes: RouteType[] = [
  {
    title: 'Documenten',
    route: '/user-documents',
    icon: <DocumentIcon />,
    badgeId: 'documents',
  },
  {
    title: 'Opdrachten',
    route: '/project',
    icon: <TaskIcon />,
    badgeId: 'projects',
  },
  {
    title: 'Urenoverzicht',
    route: '/hours',
    icon: <CalendarIcon />,
    badgeId: 'hours',
  },
  {
    title: 'Facturen',
    route: '/invoices',
    icon: <WalletIcon />,
    badgeId: 'invoices',
  },
]
