import React, { useCallback, useRef, useState } from 'react'
import Layout from 'components/Layout/Layout'
import { Button, Typography, Stack, Pagination } from '@mui/material'
import { useQuery } from '@apollo/client'
import { GET_INVOICES } from 'graphql/queries'
import DataList from 'components/common/DataBox/DataBox'
import moment from 'moment'
import 'moment/locale/nl'
import SendInvoiceForm from 'components/forms/SendInvoiceForm/SendInvoiceForm'
import FilterInvoicesForm from 'components/forms/FilterInvoicesForm/FilterInvoicesForm'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { ReactComponent as DocumentIcon } from 'assets/icons/documents.svg'
import { authApi } from 'services/Auth'

const InvoicesPage: React.FC<{ showBottomNavigation?: boolean }> = ({ showBottomNavigation }) => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_INVOICES, {
    variables: {
      current_page: '0',
    },
    notifyOnNetworkStatusChange: true,
  })

  const paginationHandler = useCallback((event: React.ChangeEvent<unknown>, value: any) => {
    refetch({
      current_page: `${value - 1}`,
    })
  }, [])

  return (
    <Layout showBottomNavigation={showBottomNavigation} loading={loading}>
      <Typography variant="h1">Facturen</Typography>

      <FilterInvoicesForm
        availableYears={data?.invoices?.available_years ?? []}
        onFilter={(filters: any) => {
          refetch({
            current_page: '0',
            ...filters,
          })
        }}
      />

      <Typography variant="body1">
        Let op, als de status van jouw factuur staat op "Concept", dan ben je nog niet klaar! Klik rechts naast de
        status “Concept” op de pijl. Vul jouw persoonlijke factuurnummer in en klik op versturen. Jouw factuur is nu
        verzonden. Zodra het meegenomen is in de eerstvolgende betaalbatch verandert de status naar “Betaald”.
      </Typography>

      <>
        {data?.invoices?.items?.map((invoice: any) => {
          return (
            <DataList
              key={invoice.id}
              title="Opdrachten"
              description={
                <ul>
                  {invoice?.related_hour?.project_hours.map((project: any) => {
                    return (
                      <li key={project.id}>{`${project.related_project.title} (${project.related_project.code})`}</li>
                    )
                  })}
                </ul>
              }
              data={[
                { key: 'Type:', value: invoice.invoice_type_name },
                { key: 'Factuur nr:', value: invoice.code },
                { key: 'Datum:', value: moment(invoice.created).format('DD-MM-YYYY') },
                {
                  key: 'Maand:',
                  value: `${invoice.related_hour.month} (${moment()
                    .locale('nl')
                    .month(invoice.related_hour.month - 1)
                    .format('MMMM')})`,
                },
                { key: 'Jaar:', value: invoice.related_hour.year },
                {
                  key: 'Bedrag:',
                  value: `€${invoice.totals
                    .reduce((acc: number, amount: any) => acc + Number(amount.display_amount.replace(',', '.')), 0)
                    .toFixed(2)}`,
                },
              ]}
              status={{ color: invoice.state_class, text: invoice.state_name }}
              button={
                <>
                  {invoice.state === 'draft' && (
                    <SendInvoiceForm invoice={invoice} loadedInvoices={data?.invoices?.items?.length} />
                  )}
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    onClick={async () => {
                      const accessToken = await authApi.getAccessToken()
                      const data = await fetch(`${process.env.REACT_APP_API_URL}/invoice/${invoice.id}/download`, {
                        method: 'GET',
                        headers: {
                          Authorization: `Bearer ${accessToken}`,
                        },
                      })
                      const documentBuffer = await data.arrayBuffer()
                      var file = new Blob([documentBuffer], { type: 'application/pdf' })
                      var fileURL = URL.createObjectURL(file)
                      location.href = fileURL
                    }}
                  >
                    Download
                  </Button>
                </>
              }
            />
          )
        })}
        {data?.invoices?.pages_total > 0 && (
          <Stack>
            <Pagination
              page={data?.invoices?.current_page + 1}
              count={data?.invoices?.pages_total + 1}
              shape="rounded"
              size="medium"
              onChange={paginationHandler}
            />
          </Stack>
        )}
        {/* {data?.invoices?.items?.length < data?.invoices?.total && (
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={(e) => {
              e.preventDefault()
              fetchMore({
                variables: {
                  offset: `${data?.invoices?.items?.length}`,
                },
              })
            }}
          >
            Laad meer
          </Button>
        )} */}
      </>
      {data?.invoices?.items?.length === 0 && (
        <Typography variant="body1">
          Geen resultaten gevonden. Pas uw zoekopdracht aan of reset de ingestelde filters
        </Typography>
      )}

      <Typography variant="h2">Betaalkalender</Typography>

      {!loading && (
        <>
          {data?.payment_calendars.map((doc: any) => {
            return (
              <DataList
                key={doc.payment_calendar_file.id}
                title={doc.payment_calendar_file.name}
                data={[
                  {
                    key: 'Upload Datum:',
                    value: doc?.payment_calendar_file?.created
                      ? moment(doc?.payment_calendar_file?.created).format('DD-MM-YYYY')
                      : 'n.v.t',
                  },
                ]}
                icon={<DocumentIcon />}
                button={
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    href={doc?.payment_calendar_file?.download_link}
                    target="_blank"
                    download
                  >
                    Download
                  </Button>
                }
              />
            )
          })}
        </>
      )}
    </Layout>
  )
}

export default InvoicesPage
