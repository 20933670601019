import React from 'react'
import { NavigationRoutes } from 'pages/routes'
import NavigationButton from '../NavigationButton/NavigationButton'
import { Grid } from '@mui/material'

type DashboardNavigationProps = {
  badge: {
    [key: string]: boolean | string
  }
}

const DashboardNavigation: React.FC<DashboardNavigationProps> = ({ badge }) => {
  return (
    <Grid container spacing={2} sx={{ marginLeft: '-16px!important' }}>
      {NavigationRoutes.map((route) => {
        return (
          <Grid item xs={6} key={route.route}>
            <NavigationButton {...route} badge={badge[route.badgeId]} />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default DashboardNavigation
