import React from 'react'
import { Table, TableCell, TableContainer, TableHead, TableRow, Paper, TableBody } from '@mui/material'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'

const DataTable: React.FC<{ head: string[]; body: ReactJSXElement }> = ({ head, body }) => {
  return (
    <TableContainer component={Paper} variant="outlined" sx={{ padding: 0, borderRadius: '6px' }}>
      <Table>
        <TableHead>
          <TableRow>
            {head.map((th) => {
              return <TableCell key={th}>{th}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>{body}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default DataTable
