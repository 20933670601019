import React, { BaseSyntheticEvent, useState } from 'react'
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { config } from 'config'
import useNotification from 'hooks/useNotification'

const NewPasswordForm = () => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [search] = useSearchParams()
  const { register, handleSubmit } = useForm({
    defaultValues: {
      password: '',
      'repeat-password': '',
    },
  })
  let navigate = useNavigate()
  const notify = useNotification()

  const onSubmit = async (data: any) => {
    if (data.password !== data['repeat-password']) {
      notify('error', 'Wachtwoord komt niet overeen')
      return
    }

    setLoading(true)

    const payload = {
      new_pass: data.password,
      temp_pass: search.get('token'),
      name: search.get('email'),
    }

    const resetPasswordRequest = await fetch(`${config.API_URL}/user/lost-password-reset?_format=json`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(payload),
    })

    setLoading(false)

    if (resetPasswordRequest.status !== 200) {
      const res = await resetPasswordRequest.json()
      notify('error', res.message)
      setSuccess(false)
    } else {
      setSuccess(true)
    }
  }

  return (
    <Box
      component="form"
      onSubmit={(e: BaseSyntheticEvent) => {
        handleSubmit(onSubmit)(e)
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'relative',
        pr: '10px',
        pl: '50px',
        '&:before': {
          content: '" "',
          width: '490px',
          height: '490px',
          background: (theme) => `${theme.palette.primary.main}DB`,
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          borderRadius: '50%',
          zIndex: 0,
        },
        '&:after': {
          content: '" "',
          width: '95px',
          height: '95px',
          background: (theme) => `${theme.palette.primary.main}90`,
          position: 'absolute',
          left: -50,
          bottom: '-100%',
          borderRadius: '50%',
        },
      }}
    >
      <Typography
        sx={{ zIndex: 1, fontSize: '30px', lineHeight: '30px', fontWeight: 'bold', mb: 2 }}
        variant="h3"
        align="right"
      >
        Nieuw wachtwoord instellen
      </Typography>
      {success ? (
        <>
          <Typography variant="body1" align="right" sx={{ zIndex: 1, mb: 2, maxWidth: '290px' }}>
            Uw verzoek om uw wachtwoord opnieuw in te stellen is succesvol verwerkt. Uw nieuwe inloggegevens zijn
            bijgewerkt en u kunt nu uw account openen met het nieuwe wachtwoord.
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate('/login')}
            sx={{
              borderRadius: '6px',
              background: (theme) => theme.palette.primary.contrastText,
              color: 'white',
              fontWeight: 'bold',
              textTransform: 'none',
              fontSize: '14px',
              // width: '125px',
              height: '45px',
              mt: '7px',
              lineHeight: 1,
            }}
          >
            Login
          </Button>
        </>
      ) : (
        <>
          <TextField type="password" placeholder="Wachtwoord" margin="dense" {...register('password')} />
          <TextField
            type="password"
            placeholder="Wachtwoord herhalen"
            margin="dense"
            {...register('repeat-password')}
          />

          <Button
            variant="contained"
            type="submit"
            size="large"
            endIcon={loading && <CircularProgress color="inherit" size={16} />}
            disabled={loading}
            sx={{
              borderRadius: '6px',
              background: (theme) => theme.palette.primary.contrastText,
              color: 'white',
              fontWeight: 'bold',
              textTransform: 'none',
              fontSize: '14px',
              // width: '125px',
              height: '45px',
              mt: '7px',
              lineHeight: 1,
            }}
          >
            Wijzigingen opslaan
          </Button>
        </>
      )}
    </Box>
  )
}

export default NewPasswordForm
