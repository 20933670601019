import React from 'react'
import { Table, TableCell, TableContainer, TableHead, TableRow, Paper, TableBody } from '@mui/material'
import TextInput from '../TextInput/TextInput'

const InputTable: React.FC<{ head: string[]; body: JSX.Element[] }> = ({ head, body }) => {
  return (
    <TableContainer component={Paper} variant="outlined" sx={{ padding: 0, borderRadius: '6px' }}>
      <Table>
        <TableHead>
          <TableRow>
            {head.map((th) => {
              return (
                <TableCell key={th} sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                  {th}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>{body}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default InputTable
