import React, { useEffect } from 'react'
import { Paper, Stack, Box, Button } from '@mui/material'
import SelectInput from 'components/common/SelectInput/SelectInput'
import TextInput from 'components/common/TextInput/TextInput'
import { useForm, Controller } from 'react-hook-form'

type filtersType = {
  title?: string
  type?: string
  state?: string
}

const projectStatuses = [
  { tid: 'All', name: '- Kies status -' },
  { tid: 'accepted', name: 'Geaccepteerd' },
  { tid: 'rejected', name: 'Afgekeurd' },
  { tid: 'sent', name: 'Verzonden' },
]

const projectTypes = [
  { tid: 'All', name: '- Kies type -' },
  { tid: 'active', name: 'Actief' },
  { tid: 'archive', name: 'Archief' },
]

const FilterProjectsForm: React.FC<{ onFilter: (data: filtersType) => void }> = ({ onFilter }) => {
  const { control, handleSubmit, reset, formState } = useForm({
    defaultValues: {
      searchValue: '',
      status: 'All',
      type: 'All',
    },
  })

  const { errors } = formState

  const onSubmit = (data: any) => {
    const filters: filtersType = {}
    filters.title = data.searchValue
    filters.state = data.status === 'All' ? '' : data.status
    filters.type = data.type === 'All' ? '' : data.type

    onFilter(filters)
  }

  return (
    <Paper variant="outlined">
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack direction="column" spacing="10px">
          <Controller
            name="searchValue"
            control={control}
            render={({ field }) => <TextInput error={errors?.searchValue?.message} placeholder="Zoeken" {...field} />}
          />
          <Controller
            name="status"
            control={control}
            render={({ field }) => <SelectInput error={errors?.status?.message} options={projectStatuses} {...field} />}
          />
          <Controller
            name="type"
            control={control}
            render={({ field }) => <SelectInput error={errors?.type?.message} options={projectTypes} {...field} />}
          />
          <Stack direction="row" spacing="10px">
            <Button variant="contained" sx={{ flexGrow: 1 }} type="submit">
              Filter
            </Button>
            <Button
              variant="outlined"
              sx={{ flexGrow: 1 }}
              type="reset"
              onClick={() => {
                reset()
                onFilter({ type: 'All', state: 'All', title: '' })
              }}
            >
              Reset
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  )
}

export default FilterProjectsForm
