import React, { useContext } from 'react'
import { NotificationsContext } from 'App'

const useNotification = () => {
  const { notifications, updateNotifications } = useContext(NotificationsContext)

  return (type: 'error' | 'info' | 'success' | 'warning', message: string) => {
    const id = Date.now().toString()
    updateNotifications([...notifications, { id: id, type: type, message: message }])
  }
}

export default useNotification
