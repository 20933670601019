import React, { useEffect } from 'react'
import Layout from 'components/Layout/Layout'
import Welcome from 'components/Dashboard/Welcome/Welcome'
import { useNavigate } from 'react-router-dom'
import DashboardNavigation from 'components/Dashboard/DashboardNavigation/DashboardNavigation'
import { useQuery } from '@apollo/client'
import { GET_PROFESSIONAL } from 'graphql/queries'
import { Button } from '@mui/material'
import { authApi } from 'services/Auth'

const HomePage: React.FC<{ showBottomNavigation?: boolean }> = ({ showBottomNavigation }) => {
  const { data, loading } = useQuery(GET_PROFESSIONAL, {
    variables: {
      id: authApi.getUserId(),
    },
    // fetchPolicy: 'network-only',
  })
  const navigate = useNavigate()

  const documentsStatus = data?.user?.documents_popup_status
  const hoursStatus = data?.user?.hours_popup_counter ?? 0
  const invoicesStatus = data?.user?.invoices_popup_counter ?? 0
  const projectsStatus = data?.user?.projects_popup_counter ?? 0

  const userName = data?.user?.full_name ?? ''
  const userImage = data?.user?.picture_url ?? ''

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches && 'setAppBadge' in navigator && data) {
      let notifications = documentsStatus ? 1 : 0
      notifications += hoursStatus + invoicesStatus + projectsStatus
      // @ts-ignore
      navigator?.setAppBadge(notifications)
    }
  }, [data])

  return (
    <Layout showBottomNavigation={showBottomNavigation} loading={loading}>
      <Welcome name={userName} avatarSrc={userImage} />
      <DashboardNavigation
        badge={{
          documents: documentsStatus,
          hours: hoursStatus,
          invoices: invoicesStatus,
          projects: projectsStatus,
        }}
      />
      <Button
        variant="contained"
        color="error"
        onClick={async () => {
          await authApi.logout()
          navigate('/login')
        }}
      >
        Uitloggen
      </Button>
    </Layout>
  )
}

export default HomePage
