import React, { useEffect, useCallback } from 'react'
import Layout from 'components/Layout/Layout'
import { Button, Typography, Stack, Pagination } from '@mui/material'
import FilterProjectsForm from 'components/forms/FilterProjectsForm/FilterProjectsForm'
import { useQuery } from '@apollo/client'
import { GET_PROJECTS } from 'graphql/queries'
import DataList from 'components/common/DataBox/DataBox'
import moment from 'moment'
import { useNavigate, useLocation } from 'react-router-dom'

type filtersType = {
  title?: string
  type?: string
  state?: string
}

const ProjectsPage: React.FC<{ showBottomNavigation?: boolean }> = ({ showBottomNavigation }) => {
  const navigate = useNavigate()
  const { hash } = useLocation()
  const { loading, data, refetch, fetchMore } = useQuery(GET_PROJECTS, {
    variables: {
      current_page: '0',
    },
    notifyOnNetworkStatusChange: true,
  })

  const paginationHandler = useCallback((event: React.ChangeEvent<unknown>, value: any) => {
    refetch({
      current_page: `${value - 1}`,
    })
  }, [])

  useEffect(() => {
    if (hash) {
      let project = document.querySelector(`#project-${hash.slice(1)}`)
      project?.scrollIntoView(true)
    } else {
      window.scrollTo({ top: 0, left: 0 })
    }
  }, [loading])

  return (
    <Layout showBottomNavigation={showBottomNavigation} loading={loading}>
      <Typography variant="h1">Opdrachten</Typography>
      <FilterProjectsForm
        onFilter={(data: filtersType) =>
          refetch({
            current_page: '0',
            ...data,
          })
        }
      />
      <Typography variant="h2">Opdrachten</Typography>

      {data?.projects?.items?.map((project: any) => {
        return (
          <DataList
            key={project.id}
            id={`project-${project.id}`}
            title={
              <>
                {project.profile.name}
                <br />
                {project.title}
              </>
            }
            data={[
              {
                key: 'Geplaatst op:',
                value: moment(project.created).format('DD-MM-YYYY'),
              },
              {
                key: 'Tarief lesgeb.',
                value: `€${project.rate}`,
              },
              {
                key: 'Tarief niet lesgeb.',
                value: `€${project.rate_other}`,
              },
              {
                key: 'Start:',
                value: moment(project.start_date).format('DD-MM-YYYY'),
              },
              {
                key: 'Eind:',
                value: moment(project.end_date).format('DD-MM-YYYY'),
              },
            ]}
            status={{ text: project.state_name, color: project.state_class }}
            button={
              <Button fullWidth variant="contained" onClick={() => navigate(`/project/${project.id}#${project.id}`)}>
                Opdracht details
              </Button>
            }
          />
        )
      })}

      {data?.projects?.pages_total > 0 && (
        <Stack>
          <Pagination
            page={data?.projects?.current_page + 1}
            count={data?.projects?.pages_total + 1}
            shape="rounded"
            size="medium"
            onChange={paginationHandler}
          />
        </Stack>
      )}

      {/* {data?.projects?.items?.length < data?.projects?.total && (
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={(e) => {
            e.preventDefault()
            navigate('/projects')
            fetchMore({
              variables: {
                offset: `${data?.projects?.items?.length}`,
              },
            })
          }}
        >
          Laad meer
        </Button>
      )} */}

      {data?.projects?.items?.length === 0 && (
        <Typography variant="body1">
          Geen resultaten gevonden. Pas uw zoekopdracht aan of reset de ingestelde filters
        </Typography>
      )}
    </Layout>
  )
}

export default ProjectsPage
