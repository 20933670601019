import React, { useEffect } from 'react'
import Layout from 'components/Layout/Layout'
import { Box, Skeleton, Typography } from '@mui/material'
import { useQuery, useMutation } from '@apollo/client'
import { GET_USER_DOCUMENTS } from 'graphql/queries'
import DataList from 'components/common/DataBox/DataBox'
import { Button } from '@mui/material'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { ReactComponent as DocumentIcon } from 'assets/icons/documents.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import moment from 'moment'
import FileInput from 'components/common/FileInput/FileInput'
import { UPLOAD_FILE, REMOVE_FILE } from 'graphql/queries'
import { toBase64 } from 'utils/toBase64'
import useNotification from 'hooks/useNotification'
import ButtonWithConfirmation from 'components/common/ButtonWithConfirmation/ButtonWithConfirmation'
import { authApi } from 'services/Auth'

const allowedFormats = '.png,.jpeg,.jpg,.txt,.doc,.docx,.pdf'

const DocumentsPage: React.FC<{ showBottomNavigation?: boolean }> = ({ showBottomNavigation }) => {
  const documents = useQuery(GET_USER_DOCUMENTS, { variables: { id: authApi.getUserId() } })

  const [uploadFile, { data: uploadData, error: uploadError, loading: uploadLoading }] = useMutation(UPLOAD_FILE)
  const [removeFile, { data: removeData, error: removeError, loading: removeLoading }] = useMutation(REMOVE_FILE)

  const notify = useNotification()

  useEffect(() => {
    uploadError && notify('error', 'Something went wrong!')
    uploadData?.createMedia?.new_media_id && notify('success', 'New file uploaded!')
    uploadData?.createMedia?.errors.length && notify('error', uploadData?.createMedia?.errors[0]?.message)
  }, [uploadData, uploadError])

  useEffect(() => {
    removeError && notify('error', 'Something went wrong!')
    removeData?.removeMedia?.status && notify('success', 'File deleted successfully!')
    removeData?.removeMedia?.errors.length && notify('error', removeData?.removeMedia?.errors[0]?.message)
  }, [removeData, removeError])

  let loading = documents.loading

  const userDocumentsId = documents?.data?.user?.user_documents?.id
  const userDocs = documents?.data?.user?.user_documents
  const templateDocs = documents?.data?.user?.template_documents
  const kor = documents?.data?.user?.kor_participant

  const removeDocument = (id: number) => {
    removeFile({
      variables: {
        id: id,
      },
      refetchQueries: [{ query: GET_USER_DOCUMENTS, variables: { id: authApi.getUserId() } }],
      awaitRefetchQueries: true,
    })
  }

  const fileSizeIsNotAcceptable = (e: any) => {
    if (e.target.files[0].size > 10000000) {
      notify('error', 'File size must be less then 10mb')
      return true
    }
    return false
  }

  return (
    <Layout showBottomNavigation={showBottomNavigation} loading={loading || uploadLoading}>
      <Typography variant="h1">Documenten</Typography>
      <div>
        <Typography variant="body1">
          Upload hier jouw verplichte documenten. Let op, pas als deze documenten zijn geupload, en goedgekeurd door
          SKVR, is jouw profiel compleet. Daarna kan de backoffice opdrachten en uren naar je versturen en kan je jouw
          uren goedkeuren en facturen opmaken.
        </Typography>
        <Typography
          variant="body1"
          component="ul"
          sx={{ marginTop: '15px', listStylePosition: 'outside', paddingLeft: '15px' }}
        >
          <li>Jouw CV is nodig om jouw ervaringsjaren te bepalen en een juiste tarief te koppelen.</li>
          <li>
            Jouw VCI (verklaring controle identiteit) is nodig ter bevestiging dat SKVR heeft getekend voor de controle
            van jouw identiteit. Je kan dit document laten tekenen door jouw SKVR contactpersoon of bij onze receptie.
            Zie het VCI formulier onderin bij de te downloaden documenten.
          </li>
          <li>
            Een VOG is alleen verplicht als je les geeft aan kinderen onder de 21 jaar. Heb je nog geen VOG of gaat jouw
            VOG verlopen? Vraag er dan 1 aan door te mailen naar hrinfo@skvr.nl.
          </li>
        </Typography>
      </div>

      <div>
        <Typography variant="h2">Documenten (verplicht)</Typography>
      </div>

      {loading && (
        <Box>
          <Skeleton variant="rectangular" sx={{ width: '100%', height: '264px', borderRadius: '6px', mb: '20px' }} />
        </Box>
      )}
      {!loading && (
        <>
          <DataList
            title="Verklaring Controle Identiteit (VCI)"
            data={[
              {
                key: 'Upload Datum:',
                value: userDocs?.vci?.file?.created ? moment(userDocs?.vci?.file?.created).format('DD-MM-YYYY') : '',
              },
              {
                key: 'Geldig tot:',
                value: userDocs?.vci?.valid_until ? moment(userDocs?.vci?.valid_until).format('DD-MM-YYYY') : 'n.v.t',
              },
            ]}
            icon={<DocumentIcon />}
            status={
              userDocs?.vci
                ? { text: userDocs?.vci?.state_name, color: userDocs?.vci?.state_class }
                : { text: 'Verplicht', color: 'error' }
            }
            downloadLink={userDocs?.vci?.file?.download_link}
            button={
              userDocs?.vci ? (
                <ButtonWithConfirmation
                  buttonProps={{
                    fullWidth: true,
                    variant: 'contained',
                    startIcon: <DeleteIcon />,
                  }}
                  confirmationButtonAction={() => removeDocument(userDocs?.vci?.id)}
                  confirmationButtonText="Verwijderen"
                  cancelButtonText="Annuleren"
                  loading={removeLoading}
                  modalTitle="Verwijderen"
                  modalContent={
                    <Box>
                      <Typography align="center">Wilt u het document VCI verwijderen?</Typography>
                    </Box>
                  }
                >
                  Verwijderen
                </ButtonWithConfirmation>
              ) : (
                <FileInput
                  label={
                    <div style={{ textAlign: 'center' }}>
                      <b>Upload een bestand</b>
                      <br />
                      <span>(Max. bestandsgrote 10mb)</span>
                    </div>
                  }
                  onChange={async (e: any) => {
                    if (fileSizeIsNotAcceptable(e)) return
                    const file = await toBase64(e.target.files[0])
                    uploadFile({
                      variables: {
                        data: {
                          file_base64_string: file,
                        },
                        file_name: e.target.files[0].name.split('.')[0],
                        new_media_type: 'vci',
                        user_doc_entity_id: userDocumentsId,
                        user_doc_entity_type: 'vci',
                      },
                      refetchQueries: [{ query: GET_USER_DOCUMENTS, variables: { id: authApi.getUserId() } }],
                      awaitRefetchQueries: true,
                    })
                  }}
                  accept={allowedFormats}
                />
              )
            }
          />
          <DataList
            title="CV (curriculum vitae)"
            data={[
              {
                key: 'Upload Datum:',
                value: userDocs?.cv?.file?.created ? moment(userDocs?.cv?.file?.created).format('DD-MM-YYYY') : '',
              },
              {
                key: 'Geldig tot:',
                value: userDocs?.cv?.valid_until ? moment(userDocs?.cv?.valid_until).format('DD-MM-YYYY') : 'n.v.t',
              },
            ]}
            icon={<DocumentIcon />}
            status={
              userDocs?.cv
                ? { text: userDocs?.cv?.state_name, color: userDocs?.cv?.state_class }
                : { text: 'Verplicht', color: 'error' }
            }
            downloadLink={userDocs?.cv?.file?.download_link}
            button={
              userDocs?.cv ? (
                <ButtonWithConfirmation
                  buttonProps={{
                    onClick: () => removeDocument(userDocs?.cv?.id),
                    fullWidth: true,
                    variant: 'contained',
                    startIcon: <DeleteIcon />,
                  }}
                  confirmationButtonAction={() => removeDocument(userDocs?.cv?.id)}
                  confirmationButtonText="Verwijderen"
                  modalTitle="Verwijderen"
                  cancelButtonText="Annuleren"
                  loading={removeLoading}
                  modalContent={
                    <Box>
                      <Typography align="center">Wilt u het document CV verwijderen?</Typography>
                    </Box>
                  }
                >
                  Verwijderen
                </ButtonWithConfirmation>
              ) : (
                <FileInput
                  label={
                    <div style={{ textAlign: 'center' }}>
                      <b>Upload een bestand</b>
                      <br />
                      <span>(Max. bestandsgrote 10mb)</span>
                    </div>
                  }
                  onChange={async (e: any) => {
                    if (fileSizeIsNotAcceptable(e)) return
                    const file = await toBase64(e.target.files[0])
                    uploadFile({
                      variables: {
                        data: {
                          file_base64_string: file,
                        },
                        file_name: e.target.files[0].name.split('.')[0],
                        new_media_type: 'cv',
                        user_doc_entity_id: userDocumentsId,
                        user_doc_entity_type: 'cv',
                      },
                      refetchQueries: [{ query: GET_USER_DOCUMENTS, variables: { id: authApi.getUserId() } }],
                      awaitRefetchQueries: true,
                    })
                  }}
                  accept={allowedFormats}
                />
              )
            }
          />
          <DataList
            title="Verklaring omtrent goed gedrag (VOG)"
            data={[
              {
                key: 'Upload Datum:',
                value: userDocs?.vog?.file?.created ? moment(userDocs?.vog?.file?.created).format('DD-MM-YYYY') : '',
              },
              {
                key: 'Geldig tot:',
                value: userDocs?.vog?.valid_until ? moment(userDocs?.vog?.valid_until).format('DD-MM-YYYY') : 'n.v.t',
              },
            ]}
            icon={<DocumentIcon />}
            status={
              userDocs?.vog
                ? { text: userDocs?.vog?.state_name, color: userDocs?.vog?.state_class }
                : { text: 'Verplicht', color: 'error' }
            }
            downloadLink={userDocs?.vog?.file?.download_link}
            button={
              userDocs?.vog ? (
                <ButtonWithConfirmation
                  buttonProps={{
                    fullWidth: true,
                    variant: 'contained',
                    startIcon: <DeleteIcon />,
                  }}
                  confirmationButtonAction={() => removeDocument(userDocs?.vog?.id)}
                  confirmationButtonText="Verwijderen"
                  cancelButtonText="Annuleren"
                  loading={removeLoading}
                  modalTitle="Verwijderen"
                  modalContent={
                    <Box>
                      <Typography align="center">Wilt u het document VOG verwijderen?</Typography>
                    </Box>
                  }
                >
                  Verwijderen
                </ButtonWithConfirmation>
              ) : (
                <FileInput
                  label={
                    <div style={{ textAlign: 'center' }}>
                      <b>Upload een bestand</b>
                      <br />
                      <span>(Max. bestandsgrote 10mb)</span>
                    </div>
                  }
                  onChange={async (e: any) => {
                    if (fileSizeIsNotAcceptable(e)) return
                    const file = await toBase64(e.target.files[0])
                    uploadFile({
                      variables: {
                        data: {
                          file_base64_string: file,
                        },
                        file_name: e.target.files[0].name.split('.')[0],
                        new_media_type: 'vog',
                        user_doc_entity_id: userDocumentsId,
                        user_doc_entity_type: 'vog',
                      },
                      refetchQueries: [{ query: GET_USER_DOCUMENTS, variables: { id: authApi.getUserId() } }],
                      awaitRefetchQueries: true,
                    })
                  }}
                  accept={allowedFormats}
                />
              )
            }
          />
        </>
      )}

      <div>
        <Typography variant="h2" sx={{ marginBottom: '15px' }}>
          Documenten (optioneel)
        </Typography>
        <Typography variant="body1">
          Hier kan je extra documenten uploaden, zoals jouw diploma's, certificaten of bevestiging deelname KOR.
        </Typography>
      </div>

      {loading && (
        <Box>
          <Skeleton variant="rectangular" sx={{ width: '100%', height: '264px', borderRadius: '6px', mb: '20px' }} />
        </Box>
      )}

      {!loading && (
        <>
          {kor && (
            <DataList
              title="KOR"
              data={[
                {
                  key: 'Upload Datum:',
                  value: userDocs?.kor?.file?.created
                    ? moment(userDocs?.kor?.file?.created).format('DD-MM-YYYY')
                    : 'n.v.t',
                },
              ]}
              icon={<DocumentIcon />}
              status={userDocs?.kor && { text: userDocs?.kor?.state_name, color: userDocs?.kor?.state_class }}
              downloadLink={userDocs?.kor?.file?.download_link}
              button={
                userDocs?.kor ? (
                  <ButtonWithConfirmation
                    buttonProps={{
                      fullWidth: true,
                      variant: 'contained',
                      startIcon: <DeleteIcon />,
                    }}
                    confirmationButtonAction={() => removeDocument(userDocs?.kor?.id)}
                    confirmationButtonText="Verwijderen"
                    cancelButtonText="Annuleren"
                    loading={removeLoading}
                    modalTitle="Verwijderen"
                    modalContent={
                      <Box>
                        <Typography align="center">Wilt u het document KOR verwijderen?</Typography>
                      </Box>
                    }
                  >
                    Verwijderen
                  </ButtonWithConfirmation>
                ) : (
                  <FileInput
                    label={
                      <div style={{ textAlign: 'center' }}>
                        <b>Upload een bestand</b>
                        <br />
                        <span>(Max. bestandsgrote 10mb)</span>
                      </div>
                    }
                    onChange={async (e: any) => {
                      if (fileSizeIsNotAcceptable(e)) return
                      const file = await toBase64(e.target.files[0])
                      uploadFile({
                        variables: {
                          data: {
                            file_base64_string: file,
                          },
                          file_name: e.target.files[0].name.split('.')[0],
                          new_media_type: 'kor',
                          user_doc_entity_id: userDocumentsId,
                          user_doc_entity_type: 'kor',
                        },
                        refetchQueries: [{ query: GET_USER_DOCUMENTS, variables: { id: authApi.getUserId() } }],
                        awaitRefetchQueries: true,
                      })
                    }}
                    accept={allowedFormats}
                  />
                )
              }
            />
          )}

          {userDocs?.extra_docs?.map((doc: any) => {
            return (
              <DataList
                key={doc.id}
                title={doc.file.name}
                data={[
                  {
                    key: 'Upload Datum:',
                    value: doc?.file?.created ? moment(doc?.file?.created).format('DD-MM-YYYY') : 'n.v.t',
                  },
                ]}
                icon={<DocumentIcon />}
                downloadLink={doc?.file?.download_link}
                button={
                  <ButtonWithConfirmation
                    buttonProps={{
                      fullWidth: true,
                      variant: 'contained',
                      startIcon: <DeleteIcon />,
                    }}
                    confirmationButtonAction={() => removeDocument(doc?.id)}
                    confirmationButtonText="Verwijderen"
                    cancelButtonText="Annuleren"
                    loading={removeLoading}
                    modalTitle="Verwijderen"
                    modalContent={
                      <Box>
                        <Typography align="center">Wilt u het document {doc.name} verwijderen?</Typography>
                      </Box>
                    }
                  >
                    Verwijderen
                  </ButtonWithConfirmation>
                }
              />
            )
          })}

          <DataList
            title="Eigen document"
            data={[]}
            icon={<DocumentIcon />}
            button={
              <FileInput
                label={
                  <div style={{ textAlign: 'center' }}>
                    <b>Upload een bestand</b>
                    <br />
                    <span>(Max. bestandsgrote 10mb)</span>
                  </div>
                }
                onChange={async (e: any) => {
                  if (fileSizeIsNotAcceptable(e)) return

                  const file = await toBase64(e.target.files[0])
                  uploadFile({
                    variables: {
                      data: {
                        file_base64_string: file,
                      },
                      file_name: e.target.files[0].name.split('.')[0],
                      new_media_type: 'document',
                      user_doc_entity_id: userDocumentsId,
                      user_doc_entity_type: 'extra_docs',
                    },
                    refetchQueries: [{ query: GET_USER_DOCUMENTS, variables: { id: authApi.getUserId() } }],
                    awaitRefetchQueries: true,
                  })
                }}
                accept={allowedFormats}
              />
            }
          />
        </>
      )}

      <div>
        <Typography variant="h2" sx={{ marginBottom: '15px' }}>
          Te downloaden documenten
        </Typography>
        <Typography variant="body1">Download hier de meest recente documenten.</Typography>
      </div>

      {loading && (
        <Box>
          <Skeleton variant="rectangular" sx={{ width: '100%', height: '264px', borderRadius: '6px', mb: '20px' }} />
        </Box>
      )}

      {!loading && (
        <>
          {templateDocs?.map((doc: any) => {
            return (
              <DataList
                key={doc.id}
                title={doc.name}
                data={[
                  {
                    key: 'Upload Datum:',
                    value: doc?.file?.created ? moment(doc?.file?.created).format('DD-MM-YYYY') : 'n.v.t',
                  },
                ]}
                icon={<DocumentIcon />}
                button={
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    href={doc?.file?.download_link}
                    target="_blank"
                    download
                  >
                    Download
                  </Button>
                }
              />
            )
          })}
        </>
      )}
    </Layout>
  )
}

export default DocumentsPage
