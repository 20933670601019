import React from 'react'
import { Stack, Typography, Skeleton, Box } from '@mui/material'
import UserAvatar from 'components/common/Avatar/Avatar'

const Welcome: React.FC<{ name: string; avatarSrc?: string | undefined }> = ({ name, avatarSrc }) => {
  return (
    <Stack direction="row" spacing={3}>
      <UserAvatar avatarSrc={avatarSrc} name={name} sx={{ width: 70, height: 70 }} />
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle1">Welkom</Typography>
        <Typography variant="h1" sx={{ wordBreak: 'break-word' }}>
          {name || <Skeleton sx={{ width: '100%' }} />}
        </Typography>
      </Box>
    </Stack>
  )
}

export default Welcome
