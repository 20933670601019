import { loader } from 'graphql.macro'

export const GET_PROFESSIONAL = loader('./Profile/professional.gql')
export const GET_PROFESSIONAL_PROFILE = loader('./Profile/profile.gql')
export const GET_USER_ROLES = loader('./Profile/getPermissions.gql')
export const GET_PROFESSIONAL_PROFILE_FORM_DATA = loader('./Profile/profileFormData.gql')
export const GET_BANKS_LIST = loader('./common/banks.gql')
export const GET_LANDS_LIST = loader('./common/lands.gql')
export const GET_TARGET_GROUP_LIST = loader('./common/targetGroup.gql')
export const GET_LESSONS_LIST = loader('./common/lessons.gql')
export const GET_SPECIALITIES_LIST = loader('./common/specialities.gql')
export const POST_PROFESSIONAL_PROFILE = loader('./Profile/professional.mutation.gql')
export const CHANGE_USER_NOTIFICATIONS = loader('./Profile/notifications.mutation.gql')
export const CHANGE_USER_PASSWORD = loader('./Profile/password.mutation.gql')
export const CHANGE_USER_PHOTO = loader('./Profile/profilePhoto.mutation.gql')
export const GET_USER_DOCUMENTS = loader('./Documents/userDocuments.gql')
export const UPLOAD_FILE = loader('./Documents/userDocumentsUpload.mutation.gql')
export const REMOVE_FILE = loader('./Documents/userDocumentsRemove.mutation.gql')
export const GET_PROJECTS = loader('./Projects/Projects.gql')
export const GET_PROJECT = loader('./Projects/Project.gql')
export const CHANGE_PROJECT_STATE = loader('./Projects/ChangeProjectState.mutation.gql')
export const GET_HOURS = loader('./Hours/Hours.gql')
export const GET_HOURS_DETAILS = loader('./Hours/HourDetails.gql')
export const CHANGE_HOURS_STATE = loader('./Hours/ChangeHoursStatus.mutation.gql')
export const GET_INVOICES = loader('./Invoices/Invoices.gql')
export const SAVE_TOKEN = loader('./PushesManagement/SaveToken.gql')
export const REMOVE_TOKEN = loader('./PushesManagement/RemoveToken.gql')
export const SEND_INVOICE = loader('./Invoices/SendInvoice.mutation.gql')
