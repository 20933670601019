import { createTheme } from '@mui/material/styles'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    blacked: true
  }
}

const skvrTheme = createTheme({
  palette: {
    primary: {
      main: '#FFD800',
      dark: '#F3D114',
      light: '#F9DF4B',
      contrastText: '#272525',
    },
    secondary: {
      main: '#F6F6F9',
      dark: '#C3C3C6',
      light: '#FFFFFF',
      contrastText: '#272525',
    },
    error: {
      main: '#9A0808',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: '#F8FAFC',
          overflow: 'hidden',
          color: '#2E2D2D',
          border: 'none',
          minWidth: '270px',
          fontSize: '14px',
          lineHeight: '18px',
          padding: 0,
          '&.Mui-error': {
            borderBottom: '2px solid #9A0808',
            background: '#9A080810',
          },
        },
        input: {
          padding: '10px 16px',
        },
        notchedOutline: {
          border: 'none',
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        ul: {
          justifyContent: 'center',
          '& .MuiPaginationItem-ellipsis': {
            minWidth: '0',
            padding: '0',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            top: '-8px',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            top: 0,
            color: '#272525',
          },
          '& .MuiInputLabel-root.MuiInputLabel-shrink': {
            top: 0,
          },
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          '& svg': {
            marginBottom: '8px',
          },
          fontSize: '10px',
          '&.Mui-selected': {
            color: '#AC9411',
            '& svg': {
              path: {
                fill: '#AC9411',
              },
            },
          },
        },
        label: {
          fontSize: '10px',
          '&.Mui-selected': {
            fontSize: '10px',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&::before': {
            content: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          background: '#F6F6F9',
          borderRadius: '6px',
          height: '75px',
          '&.Mui-expanded': {
            background: '#FFD800',
          },
        },
        content: {
          justifyContent: 'center',
          fontSize: '14px',
          fontWeight: 300,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '25px 0 15px 0',
          borderBottom: '1px solid #F2F2F2',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: '#9A0808',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'blacked' },
          style: {
            backgroundColor: '#272525',
            color: 'white',
            paddingTop: '8px',
            paddingBottom: '8px',
            height: 'unset',
            lineHeight: '21px',
            '&:hover': {
              backgroundColor: '#4A4A4A',
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            color: 'black',
            borderWidth: '2px',
          },
        },
      ],
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          textTransform: 'none',
          boxShadow: 'none',
          height: '56px',
          borderRadius: '6px',
          fontSize: '14px',
          paddingTop: '14px',
          paddingBottom: '14px',
          backgroundColor: ownerState.color === 'secondary' ? '#E5E5E5' : '',
        }),
        startIcon: {
          marginRight: '20px',
        },
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            padding: '20px',
          },
        },
      ],
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '& > th:first-of-type': {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
          },
          '& > *:last-of-type': {
            borderRight: 'none',
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root > *': {
            borderBottom: 0,
            padding: '8px 16px',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '22px',
          minHeight: '36px',
          height: 'auto',
          borderRadius: '6px',
          padding: '8px 0',
          backgroundColor:
            ownerState.color === 'success'
              ? '#C3E7C5'
              : ownerState.color === 'error'
              ? '#E6B1B1'
              : ownerState.color === 'warning'
              ? '#fff4e5'
              : '#E2F0F8',
          color:
            ownerState.color === 'success'
              ? '#3C693F'
              : ownerState.color === 'error'
              ? '#790C0C'
              : ownerState.color === 'warning'
              ? '#663c00'
              : '#00476C',
        }),
        label: {
          whiteSpace: 'break-spaces',
        },
      },
    },
  },
  typography: {
    fontFamily: ['Outfit', 'sans-serif'].join(','),
    fontWeightRegular: 300,
    h1: {
      color: '#272525',
      fontSize: '30px',
      lineHeight: '35px',
      fontWeight: 700,
    },
    h2: {
      color: '#272525',
      fontSize: '20px',
      lineHeight: '25px',
      fontWeight: 700,
    },
    subtitle1: {
      color: '#BEBEBE',
      fontSize: '30px',
      lineHeight: '35px',
      fontWeight: 300,
    },
  },
})

export default skvrTheme
