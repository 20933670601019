import React, { useEffect, useState, createContext, SetStateAction, Dispatch } from 'react'
import { Routes, Route } from 'react-router-dom'
import {
  HomePage,
  LoginPage,
  ResetPasswordPage,
  NewPasswordPage,
  FallbackPage,
  DocumentsPage,
  ProfilePage,
  ProjectsPage,
  ProjectItemPage,
  HoursPage,
  HoursItemPage,
  InvoicesPage,
  ErrorPage,
} from 'pages'
import { ThemeProvider } from '@mui/material/styles'
import skvrTheme from 'theme/theme'
import { FormVariant } from 'components/forms/LoginForm/LoginForm'
import { Backdrop, CircularProgress } from '@mui/material'
import usePrivateRoutes from 'hooks/usePrivateRoutes'
import NotificationSnackbar from 'components/common/NotificationSnackbar/NotificationSnackbar'
import { useNavigate } from 'react-router-dom'
import ErrorBoundary from 'components/Layout/ErrorBoundary'
import 'services/Firebase'
import { removeMessageToken } from 'services/Firebase'
import * as Sentry from '@sentry/react'

type NotificationsContextType = {
  id: string
  type: 'error' | 'info' | 'success' | 'warning'
  message: string
}[]

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const NotificationsContext = createContext<{
  notifications: NotificationsContextType
  updateNotifications: Dispatch<SetStateAction<NotificationsContextType>>
}>({
  notifications: [],
  updateNotifications: () => {},
})

function App() {
  const [appIsReady, setAppIsReady] = useState(false)
  const [notifications, setNotifications] = useState<NotificationsContextType>([])
  usePrivateRoutes()
  const navigate = useNavigate()

  useEffect(() => {
    setAppIsReady(true)

    window.addEventListener('offline', () => {
      navigate('/error')
    })

    window.addEventListener('online', () => {
      navigate(-1)
    })

    window.addEventListener('beforeinstallprompt', removeMessageToken)
    return () => {
      window.removeEventListener('beforeinstallprompt', removeMessageToken)
    }
  }, [])

  return (
    <ThemeProvider theme={skvrTheme}>
      <ErrorBoundary>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 2, backgroundColor: 'rgba(255, 255, 255, 1)' }}
          open={!appIsReady}
        >
          <CircularProgress color="primary" />
        </Backdrop>
        <NotificationsContext.Provider value={{ notifications: notifications, updateNotifications: setNotifications }}>
          <SentryRoutes>
            <Route path="/" element={<HomePage showBottomNavigation={false} />} />
            <Route path="/error" element={<ErrorPage showBottomNavigation={false} />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />
            <Route path="new-password" element={<NewPasswordPage />} />
            <Route path="user-documents" element={<DocumentsPage />} />
            <Route path="project" element={<ProjectsPage />} />
            <Route path="project/:projectId" element={<ProjectItemPage />} />
            <Route path="hours" element={<HoursPage />} />
            <Route path="hours/:hoursId" element={<HoursItemPage />} />
            <Route path="invoices" element={<InvoicesPage />} />
            <Route
              path="login"
              element={
                <LoginPage
                  form={{
                    title: (
                      <span>
                        De Docenten
                        <br />
                        App van SKVR
                      </span>
                    ),
                    subtitle: 'Portal',
                    variant: FormVariant.LOGIN,
                  }}
                />
              }
            />
            <Route path="*" element={<FallbackPage />} />
          </SentryRoutes>
          <NotificationSnackbar />
        </NotificationsContext.Provider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
