// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { config } from 'config'
import { SAVE_TOKEN, REMOVE_TOKEN } from 'graphql/queries'
import client from 'graphql/client'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAUIU8FnUcV1pHw3NfU5y4QqnKrHa1Wy6c',
  authDomain: 'skvr-zp.firebaseapp.com',
  projectId: 'skvr-zp',
  storageBucket: 'skvr-zp.appspot.com',
  messagingSenderId: '774303780598',
  appId: '1:774303780598:web:3b92b63cf66446ff8d66d7',
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(firebaseApp)
// messaging.getToken({ vapidKey: config.FIREBASE_KEY })

export const getMessageToken = (id) => {
  return getToken(messaging, { vapidKey: config.FIREBASE_KEY })
    .then((currentToken) => {
      if (currentToken) {
        const res = client.mutate({
          mutation: SAVE_TOKEN,
          variables: { fcm_api_token: currentToken, user_id: id },
        })
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.')
        // setTokenFound(false)
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
      // catch error while creating client token
    })
}

export const removeMessageToken = (id) => {
  return getToken(messaging, { vapidKey: config.FIREBASE_KEY })
    .then((currentToken) => {
      if (currentToken) {
        const res = client.mutate({
          mutation: REMOVE_TOKEN,
          variables: { fcm_api_token: currentToken, user_id: id },
        })
      } else {
        console.log('No registration token available. Request permission to generate one.')
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
    })
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })

// export default messaging
