import React from 'react'
import { Box, Container } from '@mui/material'
import loginCover from 'assets/images/login-cover.png'
import ResetPasswordForm from 'components/forms/ResetPasswordForm/ResetPasswordForm'
import Logo from 'components/Logo/Logo'

const ResetPasswordPage = () => {
  return (
    <Box
      sx={{
        background: `url(${loginCover})`,
        minHeight: '100vh',
        backgroundRepeat: 'no-repeat',
        overflow: 'hidden',
        backgroundSize: {
          xs: 'cover',
          md: 'contain',
        },
        backgroundPosition: {
          xs: 'center',
          md: 'left',
        },
      }}
    >
      <Container
        maxWidth="lg"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: '100vh' }}
      >
        <Logo
          sx={{
            position: 'absolute',
            top: '20px',
            left: '25px',
          }}
        />
        <ResetPasswordForm />
      </Container>
    </Box>
  )
}

export default ResetPasswordPage
