import React, { useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { authApi } from 'services/Auth'

const publicRoutes = ['/reset-password', '/new-password']

const usePrivateRoutes = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const destination = search.get('d') ?? ''
  useEffect(() => {
    authApi.getAccessToken().then((accessToken) => {
      console.log('usePrivateRoutes', { accessToken })
      if (!accessToken && !publicRoutes.includes(location.pathname)) {
        navigate(`/login${location.search}`)
      }
      if (accessToken && location.pathname === '/login') {
        navigate('/')
      }
      if (accessToken && destination) {
        navigate(`/${destination}`)
      }
    })
  }, [location.pathname])
}

export default usePrivateRoutes
