import { Box, SxProps } from '@mui/material'
import React from 'react'
import { ReactComponent as LogoImage } from 'assets/Logo.svg'

const Logo: React.FC<{ sx?: SxProps }> = ({ sx }) => (
  <Box
    sx={{
      background: '#F9DF4B',
      width: '105px',
      height: '105px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px',
      boxSizing: 'border-box',
      ...sx,
    }}
  >
    <LogoImage />
  </Box>
)

export default Logo
