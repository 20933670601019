import { config } from 'config'
import client from 'graphql/client'
import { GET_USER_ROLES } from 'graphql/queries'
import * as localforage from 'localforage'
import { getMessageToken, removeMessageToken } from './Firebase'

async function postData(payload: { [key: string]: string | undefined }) {
  const formData = new FormData()

  for (const [key, value] of Object.entries(payload)) {
    if (value) {
      formData.append(key, value)
    }
  }

  return await fetch(config.API_URL + '/oauth/token', {
    method: 'POST',
    body: formData,
  })
}

class AuthApi {
  _tokenKey = 'skvr-zp-auth'
  _permanentLoginKey = 'skvr-zp-auth-permanent'
  _userIdKey = 'skvr-zp-user-id'

  store = localforage.createInstance({ name: this._userIdKey })

  async login({ username, password, permanentLogin }: { username: string; password: string; permanentLogin: boolean }) {
    let result = await postData({
      username: username,
      password: password,
      client_secret: config.OAUTH_CLIENT_KEY,
      grant_type: 'password',
      client_id: config.OAUTH_CLIENT_ID,
      scope: config.OAUTH_SCOPE,
    })
    const resultJson = await result.json()
    if (result?.status === 200) {
      window.localStorage.setItem(this._permanentLoginKey, JSON.stringify(permanentLogin))
      this.updateToken(resultJson)
      const { isProfessional, id } = await this.checkPermissions()
      if (!isProfessional) {
        await this.logout()
        return { error: { message: 'This user is not allowed' } }
      }
      this.storage().setItem(this._userIdKey, id)
      this.store.setItem(this._userIdKey, id)
      getMessageToken(id)
    }
    return resultJson
  }

  storage() {
    let result = window.localStorage.getItem(this._permanentLoginKey)
    if (!result) {
      return window.sessionStorage
    }
    const permanentLogin = JSON.parse(result)
    if (permanentLogin) {
      return window.localStorage
    }
    return window.sessionStorage
  }

  updateToken(token: { access_token: string; refresh_token: string; expires_in: number } | null) {
    if (token) {
      // adding amount of seconds token will expired in (in ms) to current time
      const expiration_date = new Date().getTime() + token.expires_in * 1000
      this.storage().setItem(
        this._tokenKey,
        JSON.stringify({ access: token.access_token, refresh: token.refresh_token, expiration_date: expiration_date }),
      )
      this.store.setItem(this._tokenKey, token.access_token)
    } else {
      this.storage().removeItem(this._tokenKey)
      this.store.removeItem(this._tokenKey)
    }
  }

  async getAccessToken() {
    let result = this.storage().getItem(this._tokenKey)
    if (!result) {
      return ''
    }
    const authData = JSON.parse(result)
    const isExpired = new Date().getTime() > authData.expiration_date
    if (isExpired) {
      const newToken = await this.refresh()
      return newToken?.access_token ?? ''
    }
    return authData.access
  }

  getRefreshToken() {
    let result = this.storage().getItem(this._tokenKey)
    if (!result) {
      return ''
    }
    return JSON.parse(result).refresh
  }

  tokenIsExist() {
    return !!this.storage().getItem(this._tokenKey)
  }

  async logout() {
    await removeMessageToken(this.getUserId())
    this.updateToken(null)
    this.storage().removeItem(this._userIdKey)
    this.store.removeItem(this._userIdKey)
  }

  getUserId() {
    return Number(this.storage().getItem(this._userIdKey))
  }

  async refresh() {
    try {
      var result = await postData({
        grant_type: 'refresh_token',
        client_secret: config.OAUTH_CLIENT_KEY,
        client_id: config.OAUTH_CLIENT_ID,
        scope: config.OAUTH_SCOPE,
        refresh_token: this.getRefreshToken(),
      })
      const resultJson = await result.json()
      if (result?.status === 200) {
        this.updateToken(resultJson)
        return resultJson
      } else {
        this.logout()
        return ''
      }
    } catch {
      this.logout()
      return ''
    }
  }

  async checkPermissions() {
    try {
      const { data } = await client.query({ query: GET_USER_ROLES, fetchPolicy: 'network-only' })
      return {
        isProfessional: data.current_professional.roles.includes(config.OAUTH_SCOPE),
        id: data.current_professional.id,
      }
    } catch {
      return {
        isProfessional: false,
        id: '',
      }
    }
  }
}

export const authApi = new AuthApi()
export const userIdKey = authApi._userIdKey
export const accessTokenKey = authApi._tokenKey
