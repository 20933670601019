import React, { useId } from 'react'
import { Box } from '@mui/material'
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg'

type FileInputProps = {
  label: any
  [prop: string]: any
}

const FileInput: React.FC<FileInputProps> = React.forwardRef((props, ref) => {
  const { label, ...rest } = props
  const id = useId()
  return (
    <>
      <Box
        component="label"
        htmlFor={id}
        sx={{
          border: '1px dashed #BADBED',
          color: (theme) => theme.palette.primary.contrastText,
          background: '#F8FAFC',
          borderRadius: '6px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '20px',
          boxSizing: 'border-box',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        <Box
          component={UploadIcon}
          sx={{
            width: '25px',
            height: '25px',
            marginRight: '5px',
          }}
        />
        {label}
      </Box>
      <input type="file" id={id} hidden {...rest}></input>
    </>
  )
})

export default FileInput
