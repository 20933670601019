import { Avatar, AvatarProps, Skeleton } from '@mui/material'
import React from 'react'

function stringToColor(string: string) {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

function stringAvatar(name: string) {
  const nameArray = name.split(' ')
  const firstName = nameArray[0]
  const lastName = nameArray[nameArray?.length - 1]
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${firstName[0]}${lastName[0]}`,
  }
}

interface UserAvatarProps extends AvatarProps {
  name: string
  avatarSrc?: string | undefined
}

const UserAvatar: React.FC<UserAvatarProps> = ({ name, avatarSrc, ...props }) => {
  if (!name) {
    return <Skeleton variant="circular" sx={{ ...props.sx }} />
  }
  return <Avatar src={avatarSrc} {...stringAvatar(name)} alt={name} {...props} />
}

export default UserAvatar
