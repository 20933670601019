import { Box, Checkbox, InputLabel, Stack, Typography } from '@mui/material'
import React, { useId, ReactNode } from 'react'

interface CheckBoxInputProps {
  label?: string | ReactNode
  required?: boolean
  color?: 'primary' | 'secondary'
  error?: string
  [prop: string]: any
}

const CheckboxInput: React.FC<CheckBoxInputProps> = React.forwardRef((props, ref) => {
  const { label, required = false, color = 'primary', error, ...rest } = props
  const id = useId()
  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Checkbox
          id={id}
          color={color}
          sx={{ '&.MuiCheckbox-root': { p: 0 } }}
          checked={rest.value}
          required={required}
          {...rest}
        />
        {label && (
          <InputLabel error={!!error} htmlFor={id} required={required} sx={{ whiteSpace: 'break-spaces' }}>
            {label}
          </InputLabel>
        )}
      </Stack>
      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </Box>
  )
})

export default CheckboxInput
