import React from 'react'
import Layout from 'components/Layout/Layout'
import { Button, TableCell, TableRow, Typography } from '@mui/material'
import Spoiler from 'components/common/Spoiler/Spoiler'
import { SpoilerVariant } from 'components/common/Spoiler/Spoiler'
import { ReactComponent as PresentationIcon } from 'assets/icons/presentation.svg'
import TextInput from 'components/common/TextInput/TextInput'
import SelectInput from 'components/common/SelectInput/SelectInput'
import CheckboxInput from 'components/common/CheckboxInput/CheckboxInput'
import RadioInput from 'components/common/RadioInput/RadioInput'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import FilterProjectsForm from 'components/forms/FilterProjectsForm/FilterProjectsForm'
import DataTable from 'components/common/DataTable/DataTable'
import InputTable from 'components/common/InputTable/InputTable'
import DataList from 'components/common/DataBox/DataBox'
import { ReactComponent as DocumentIcon } from 'assets/icons/documents.svg'

const mockOptions = [
  { tid: '2022', name: '2022' },
  { tid: '2021', name: '2021' },
  { tid: '2020', name: '2020' },
  { tid: '2019', name: '2019' },
  { tid: '2018', name: '2018' },
]

function createData(dag: string, lesgeb: string, niet_lesgeb: string) {
  return { dag, lesgeb, niet_lesgeb }
}

const rows = [
  createData('Ma.', '5.00', '1.00'),
  createData('Di.', '0.00', '0.00'),
  createData('Wo.', '0.00', '0.00'),
  createData('Do.', '0.00', '0.00'),
  createData('Vr.', '0.00', '0.00'),
  createData('Za.', '0.00', '0.00'),
  createData('Zo.', '0.00', '0.00'),
]

const dates = ['Ma. 31/01', 'Di. 01/02', 'Wo. 02/02', 'Do. 03/02', 'Vr. 04/02', 'Za. 05/02', 'Zo. 06/02']

const DataBody = () => {
  return (
    <>
      {rows.map((row) => (
        <TableRow key={row.dag}>
          <TableCell component="th" scope="row">
            {row.dag}
          </TableCell>
          <TableCell>{row.lesgeb}</TableCell>
          <TableCell>{row.niet_lesgeb}</TableCell>
        </TableRow>
      ))}
    </>
  )
}

const dataListData = [
  { key: 'Type', value: 'Uren BTW vrijgesteld' },
  { key: 'Factuur nr', value: '2022015' },
  { key: 'Datum', value: '09-03-2022' },
  { key: 'Maand', value: '2 (februari)' },
  { key: 'Jaar', value: '2022' },
  { key: 'Bedrag', value: '€1.306,44' },
]

const dataBoxData = [{ key: 'Upload datum', value: '23-08-2021' }]

const ElementsPage: React.FC<{ showBottomNavigation?: boolean }> = ({ showBottomNavigation }) => {
  return (
    <Layout>
      <Typography variant="h1">Mijn profiel</Typography>
      <Typography variant="h2">Mijn profiel</Typography>
      {/* <ProfileSettings profilePhoto="" profileName="Amanda Block" /> */}
      <Typography variant="body1">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nec nunc nunc. Curabitur quis est tincidunt arcu
        faucibus suscipit. Quisque a interdum nisi, sit amet tempus metus. Sed et mauris quam. Interdum et malesuada
        fames ac ante ipsum primis in faucibus. In nec mattis augue. Donec in mattis nisl.
      </Typography>
      <Spoiler title="Persoonlijke gegevens">
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo
          lobortis eget.
        </Typography>
      </Spoiler>
      <Spoiler title="Persoonlijke gegevens" variant={SpoilerVariant.secondary} icon={<PresentationIcon />}>
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo
          lobortis eget.
        </Typography>
      </Spoiler>
      <TextInput label="Text input" required />
      <TextInput label="Number input" type="number" />
      <TextInput label="Date input" type="date" />
      <TextInput label="TextArea input" multiline={true} rows={5} />
      <SelectInput label="Select input" options={mockOptions} required></SelectInput>
      <SelectInput label="Multiple select input" options={mockOptions} multiple></SelectInput>
      <CheckboxInput label="Ik wil ingelogd blijven" color="primary" required />
      <CheckboxInput label="Ik ga akkoord met de gebruikersvoorwaarden" color="primary" required />
      <RadioInput label="Gender" options={mockOptions} required />
      {/* <FilterProjectsForm /> */}
      <Button variant="contained" startIcon={<DownloadIcon />}>
        Contained
      </Button>
      <Button variant="contained">Contained</Button>
      <Button variant="contained" color="secondary">
        Contained
      </Button>
      <DataTable head={['Dag', 'Lesgeb.', 'Niet Lesgeb']} body={<DataBody />} />
      {/* <InputTable head={['', 'Lesuren.', 'Niet Lesuren', 'KM’s']} rowHead={dates} /> */}
      <DataList
        title="Opdrachten"
        description="Hier kan je extra documenten uploaden, zoals jouw diploma's, certificaten of bevestiging deelname KOR."
        data={dataListData}
      />
      <DataList title="Betaalkalender 2021-2022" data={dataBoxData} icon={<DocumentIcon />} />
    </Layout>
  )
}

export default ElementsPage
