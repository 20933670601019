import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material'
import React, { PropsWithChildren, useState } from 'react'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'

type ButtonWithConfirmationProps = {
  buttonProps: ButtonProps
  confirmationButtonText: string
  confirmationButtonAction: () => void
  cancelButtonText: string
  loading: boolean
  modalContent: ReactJSXElement
  modalTitle: string
}

const ButtonWithConfirmation: React.FC<PropsWithChildren<ButtonWithConfirmationProps>> = ({
  buttonProps,
  children,
  confirmationButtonText,
  confirmationButtonAction,
  cancelButtonText,
  loading,
  modalContent,
  modalTitle,
}) => {
  const [open, openModal] = useState(false)
  return (
    <>
      <Dialog open={open} fullWidth onClose={() => openModal(false)}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {modalTitle}
          <IconButton aria-label="close" onClick={() => openModal(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: '15px' }}>{modalContent}</Box>
          <Stack spacing="10px" direction="row">
            <Button
              variant="contained"
              disabled={loading}
              fullWidth
              onClick={confirmationButtonAction}
              endIcon={loading && <CircularProgress color="inherit" size={16} />}
            >
              {confirmationButtonText}
            </Button>
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              onClick={() => {
                openModal(false)
              }}
            >
              {cancelButtonText}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <Button {...buttonProps} onClick={() => openModal(true)}>
        {children}
      </Button>
    </>
  )
}

export default ButtonWithConfirmation
