import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material'
import React from 'react'

interface RadioInputProps {
  label?: string
  required?: boolean
  options: {
    tid: string
    name: string
  }[]
  error?: string
  [prop: string]: any
}

const RadioInput: React.FC<RadioInputProps> = React.forwardRef((props, ref) => {
  const { label, required = false, options, error, ...rest } = props
  return (
    <FormControl>
      <FormLabel
        required={required}
        error={!!error}
        sx={{
          fontSize: '14px',
          lineHeight: '21px',
          mb: '2px',
          '& .MuiFormLabel-asterisk': { color: (theme) => theme.palette.error.main },
          '&.Mui-focused': { color: 'rgba(0,0,0,0.6)' },
        }}
      >
        {label}
      </FormLabel>
      <RadioGroup {...rest}>
        {options.map((option) => {
          return (
            <FormControlLabel
              key={option.tid}
              value={option.tid}
              control={<Radio required={required} />}
              label={option.name}
              sx={{ whiteSpace: 'break-spaces' }}
            />
          )
        })}
      </RadioGroup>
      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </FormControl>
  )
})

export default RadioInput
