import React, { useEffect } from 'react'
import { Box, Container } from '@mui/material'
import loginCover from 'assets/images/login-cover.png'
import LoginForm from 'components/forms/LoginForm/LoginForm'
import { LoginFormProps } from 'components/forms/LoginForm/LoginForm'
import Logo from 'components/Logo/Logo'

type LoginPageProps = {
  form: LoginFormProps
}

const LoginPage: React.FC<LoginPageProps> = ({ form }) => {
  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches && 'clearAppBadge' in navigator) {
      // @ts-ignore
      navigator?.clearAppBadge()
    }
  }, [])

  return (
    <Box
      sx={{
        background: `url(${loginCover})`,
        minHeight: '100vh',
        backgroundRepeat: 'no-repeat',
        overflow: 'hidden',
        backgroundSize: {
          xs: 'cover',
          md: 'contain',
        },
        backgroundPosition: {
          xs: 'center',
          md: 'left',
        },
      }}
    >
      <Container
        maxWidth="lg"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: '100vh' }}
      >
        <Logo
          sx={{
            position: 'absolute',
            top: '20px',
            left: '25px',
          }}
        />
        <LoginForm {...form} />
      </Container>
    </Box>
  )
}

export default LoginPage
