import React from 'react'
import { RouteType } from 'pages/routes'
import { Button, Badge } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const NavigationButton: React.FC<RouteType> = ({ title, route, icon, badge }) => {
  const navigate = useNavigate()
  return (
    <Badge
      invisible={!badge}
      badgeContent={typeof badge === 'boolean' ? '!' : `${badge}`}
      color="error"
      sx={{ width: '100%', '& .MuiBadge-badge': { width: '30px', height: '30px', borderRadius: '15px' } }}
    >
      <Button
        sx={{
          height: '130px',
          background: (theme) => theme.palette.secondary.main,
          borderRadius: '15px',
          width: '100%',
          flexDirection: 'column',
          fontSize: '12px',
          color: '#000',
          justifyContent: 'flex-end',
          textTransform: 'none',
          padding: '17px',
          fontWeight: 300,
          '& > svg:first-of-type': {
            marginBottom: '25px',
          },
        }}
        onClick={() => navigate(route)}
      >
        {icon}
        {title}
      </Button>
    </Badge>
  )
}

export default NavigationButton
