import React, { Suspense, useEffect, useState } from 'react'
import Layout from 'components/Layout/Layout'
// import ProfileForm from 'components/forms/ProfileForm/ProfileForm'
import { Box, Skeleton, Typography } from '@mui/material'
import { useQuery } from '@apollo/client'
import { authApi } from 'services/Auth'
import { GET_PROFESSIONAL_PROFILE, GET_PROFESSIONAL, GET_PROFESSIONAL_PROFILE_FORM_DATA } from 'graphql/queries'

const ProfileForm = React.lazy(() => import('components/forms/ProfileForm/ProfileForm'))

const ProfilePage: React.FC<{ showBottomNavigation?: boolean }> = ({ showBottomNavigation }) => {
  const [formLoading, setFormLoading] = useState<boolean>(true)

  useEffect(() => {
    setTimeout(() => {
      setFormLoading(false)
    }, 100)
  }, [])

  const profile = useQuery(GET_PROFESSIONAL_PROFILE, {
    variables: {
      id: authApi.getUserId(),
    },
    notifyOnNetworkStatusChange: true,
  })
  const user = useQuery(GET_PROFESSIONAL, {
    variables: {
      id: authApi.getUserId(),
    },
    notifyOnNetworkStatusChange: true,
  })
  const formOptions = useQuery(GET_PROFESSIONAL_PROFILE_FORM_DATA, {
    variables: {
      id: authApi.getUserId(),
    },
    notifyOnNetworkStatusChange: true,
  })

  let loading = profile.loading || user.loading || formOptions.loading || formLoading

  const profileValues = profile.data?.user_professional_profile
  const userValues = user.data?.user
  const formOptionsValues = formOptions.data

  return (
    <Layout showBottomNavigation={showBottomNavigation} loading={loading}>
      <Typography variant="h1">Professional</Typography>
      {loading && (
        <Box>
          <Skeleton variant="rectangular" sx={{ width: '100%', height: '75px', borderRadius: '6px', mb: '10px' }} />
          <Skeleton variant="rectangular" sx={{ width: '100%', height: '75px', borderRadius: '6px', mb: '10px' }} />
          <Skeleton variant="rectangular" sx={{ width: '100%', height: '75px', borderRadius: '6px', mb: '10px' }} />
          <Skeleton variant="rectangular" sx={{ width: '100%', height: '75px', borderRadius: '6px', mb: '10px' }} />
          <Skeleton variant="rectangular" sx={{ width: '100%', height: '75px', borderRadius: '6px', mb: '10px' }} />
        </Box>
      )}
      <Suspense
        fallback={
          <Box>
            <Skeleton variant="rectangular" sx={{ width: '100%', height: '75px', borderRadius: '6px', mb: '10px' }} />
            <Skeleton variant="rectangular" sx={{ width: '100%', height: '75px', borderRadius: '6px', mb: '10px' }} />
            <Skeleton variant="rectangular" sx={{ width: '100%', height: '75px', borderRadius: '6px', mb: '10px' }} />
            <Skeleton variant="rectangular" sx={{ width: '100%', height: '75px', borderRadius: '6px', mb: '10px' }} />
            <Skeleton variant="rectangular" sx={{ width: '100%', height: '75px', borderRadius: '6px', mb: '10px' }} />
          </Box>
        }
      >
        {!loading && (
          <ProfileForm defaultValues={profileValues} formOptions={formOptionsValues} profileData={userValues} />
        )}
      </Suspense>
    </Layout>
  )
}

export default ProfilePage
